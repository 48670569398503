import React, { useState, useEffect } from 'react';
import { Paper, Popover } from '@material-ui/core';
import { Typography, TextField } from '@mui/material';
import { Button } from '@material-ui/core';
import Services from '../services/Services';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import SuccessDialog from "../input/SuccessDialog";
import AlertDialog from './AlertDialog';


const EmailPopup = (reportData) => {
  const finalReportData = reportData.reportData;
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');
  const kaizenTypeName = finalReportData.kaizen_type || '';
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(null);
  const userData = JSON.parse(localStorage.getItem('userData'));
  let email = '';
  if (userData !== null && Object.keys(userData).length !== 0) {
    email = userData.email_id;
  } else {
    console.log('userData is empty');
  }

  const handleClick = (event) => {
    if (event?.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCancel = () => {
    setAnchorEl(null);
    setError(null);
  };
  const handleMessageInput = (event) => {
    setMessage(event.target.value);
  };



  const uploadPPT = async (reportData,userData) => {
    let beforeData, afterData;
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;

    let parsedData = [];
    let parsedAfterData = [];
    beforeData = reportData.before_status[0];
    parsedData = beforeData.before_image;
    afterData = reportData.after_status[0];
    parsedAfterData = afterData.after_image;
    const beforeDescription = beforeData.description;
    const afterDescription = afterData.description;

    const formData = new FormData();
    formData.append('kaizen_name', reportData.kaizen_name);
    formData.append('kaizen_type',kaizenTypeName);
    formData.append('serial_number', reportData.serial_number);
    formData.append('before_description',beforeDescription);
    formData.append('after_description',afterDescription);
    formData.append('before_image_array', JSON.stringify(parsedData));
    formData.append('after_image_array', JSON.stringify(parsedAfterData));
    const response = await axios.post(
      Services.UPLOAD_PPT,
      formData,
      {
      headers: {
        Authorization: `Bearer ${access_token}`,
        secret_key: secret_key
      }
    }
    );
    
    if (response.status === 200) {
      console.log("responseresponse", response.data);
      const filePath = response.data.filePath;
      const fileName = response.data.fileName;
      const base64 = response.data.base64;
      await insertReportStatus(filePath,fileName,base64);      
    } else {
      console.log("error", response);
    }
  }

  const insertReportStatus = async (filePath,fileName, base64) => {
    try {
      // Define the data to be sent in the request body
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
  
      const formData = new FormData();
      formData.append('to_mail',email);
      formData.append('message',message);
      formData.append('file_path', filePath);
      formData.append('kaizen_id', finalReportData.id);
      formData.append('user_id', finalReportData.user_id);
      // Make the POST request using Axios
      const response = await axios.post(Services.INSERT_REPORT,formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            secret_key: secret_key
          }
        });
      if (response.status === 200) {
        console.log("responsee", response);
         await uploadMail(filePath,fileName, base64);
        handleClose();
      }
      console.log(response.data); // Log the response data
      // Handle the response here (e.g. update state, display a success message)
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    } finally {
      console.log("test");
    }
  };

  const handleSend = async () => {
     setIsLoading(true);
     await uploadPPT(finalReportData,userData);
  };

  const uploadMail = async(filePath,fileName, base64) => {

    const formData = new FormData();
    formData.append('to', email); 
    formData.append('message', message);
    formData.append('fileName', fileName);
    formData.append('filePath', filePath);
    formData.append('base64Data', base64);
    try {
      setIsLoading(false);
      const response =  axios.post(Services.SEND_MAIL_API, formData, {
        // headers: {
        //   Authorization: `Bearer ${access_token}`,
        //   secret_key: secret_key
        // }
      });
      console.log('Email sent successfully:', response.data);
      handleClose();
    } catch (error) {
      console.error('Error sending email:', error.response.data);
    }
    
  }
  const handleClose = () => {
    setAnchorEl(null);
    setError(null);
  };
  
  const onHandleClosed = () => {
    setShowDialog(false);
  }

  useEffect(() => {
    const event = { currentTarget: document.body }; // Create a synthetic event object
    handleClick(event); // Automatically open the popover when the component mounts
  }, []);

  const popoverContent = (
    <div>
      <SuccessDialog open={showDialog} onClose={onHandleClosed} msg={"Email sent successfully"} />
      {isLoading && (
      <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
      </div>
    )}
      {error && (
      <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <div>Error: {error}</div>
      </div>
    )}
    <div style={{ padding: 16, width: '100vw', boxSizing: 'border-box' }}>
      <div style={{ display: 'flex-start', justifyContent: 'center', alignItems: 'center' }}>
        <Typography style={{ fontSize: 18, fontWeight: 600 }}>Kaizen Report</Typography>
        <Typography>Overall, Sekurit India took and finished a Kaizen.</Typography>
      </div>

      <div>
        <TextField
          style={{ marginTop: 20, textAlign: 'left', width: '300px' }}
          id="outlined-read-only-input"
          label="E-Mail ID"
          value={email}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
        <TextField
          id="message"
          label="Message"
          variant="outlined"
          size="normal"
          style={{ width: '300px', flex: '0.5' }}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '18px' },
          }}
          onChange={handleMessageInput}
          multiline={true}
          rows={5}
        ></TextField>
      </div>

      <div style={{ display: 'flex', width: '300px', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <Button
          variant="outlined"
          style={{
            borderRadius: '3px',
            marginLeft: 20,
            marginRight: 20,
            flex: 1,
            borderWidth: '3px',
            border: '2px solid #00448B',
            fontSize: 20,
            color: '#00448B',
            marginTop: '10px',
            textTransform: 'none',
            height: '45px',
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          style={{
            borderRadius: '3px',
            flex: 1,
            backgroundColor: '#00448B',
            fontSize: 20,
            color: 'white',
            marginTop: '10px',
            textTransform: 'none',
            height: '45px',
          }}
          onClick={handleSend}
        >
          Send
        </Button>
      </div>
    </div>
    </div>
    
  );
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
 
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }} // Center of the screen
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Paper style={{ width: '360px' }}>{popoverContent}</Paper>
      </Popover>
    </div>
  );
};
export default EmailPopup;
