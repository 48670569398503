
import pptxgen from 'pptxgenjs';
import Services from "./Services";
import axios from "axios";
const ExcelJS = require('exceljs');


const Helper = {
  generatePresentation(getReportData) {
    const beforeData = getReportData.before_status;
    const afterData = getReportData.after_status;
    const serialNumber = getReportData.serial_number;
    let beforeImageName, beforeDescription = '';
    let afterImageName, afterDescription = '';

    if (beforeData) {
      let beforeImage = beforeData[0].before_image
      const imagesString = beforeImage.split(",");
      const jsonArray = JSON.parse(imagesString);
      const firstImage = jsonArray[0];
      beforeImageName = firstImage.name;
      beforeDescription = beforeData[0].description;
    }
    if (afterData) {
      let afterImage = afterData[0].after_image;
      const imagesString = afterImage.split(",");
      const jsonArray = JSON.parse(imagesString);
      const firstImage = jsonArray[0];
      afterImageName = firstImage.name;
      afterDescription = afterData[0].description;
    }


    const ppt = new pptxgen();
    const slide = ppt.addSlide();

    // Set slide properties to remove the space at the top
    slide.back = 'FFFFFF'; // Set slide background color to white
    slide.margin = [0, 0, 0, 0]; // Set slide margins to 0

    const imagePathBefore = beforeImageName;
    const imagePathAfter = afterImageName;
    console.log("imagePathBefore", imagePathBefore);
    console.log("imagePathAfter", imagePathAfter);

    slide.addText(
      getReportData.kaizen_name,
      {
        x: 1,
        y: 0.1,
        w: '80%',
        h: 0.5,
        fontSize: 25,
        align: 'center',
        color: '008899',
      }
    );

    // Kaizen type started
    const tableOptions = {
      x: 6.5,
      y: 0.1,
      w: '30%',
      fill: 'FFFFFF',
      fontSize: 12,
      border: {
        pt: 1,
        color: '000000',
      },
      fillCell: (rowIndex, cellIndex) => {
        const headers = ['P', 'Q', 'C', 'D', 'S', 'M', 'S'];
        if (rowIndex === 0 && headers[cellIndex] && headers[cellIndex] !== 'S') {
          return '0000FF'; // Blue color for header cells
        }
        return null; // No background color for other cells
      }
    };

    const data = [
      ['P', 'Q', 'C', 'D', 'S', 'M', 'S'],
      ['Productivity', 'Quality', 'Cost', 'Delivery', 'Safety', 'Moral', 'Standards']
    ];
    const shouldShowTickMark = ['Productivity', 'Quality', 'Cost', 'Delivery', 'Safety', 'Moral', 'Standards'].includes(getReportData.kaizen_type);
    const tickSymbol = shouldShowTickMark ? '\u2713' : '';

    // Iterate over each row in the data array
    data.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        if (rowIndex === 0) {
          if (cellIndex === row.length - 1) {
            row[cellIndex] = { text: cell, options: { fill: { color: '92d050' }, font: { color: 'FFFFFF' } } }; // Green color for last cell in the first row
          } else {
            row[cellIndex] = { text: cell, options: { fill: { color: '4472C4' }, font: { color: 'FFFFFF' } } }; // Blue color for other cells in the first row
          }
        } else if (rowIndex === 1 && [getReportData.kaizen_type].includes(cell)) {
          row[cellIndex] = { text: shouldShowTickMark ? tickSymbol : '', options: { fill: { color: 'FFFFFF' }, font: { color: 'FFFFFF' } } }; // Yellow color and white font for specified kaizen_type cells in the second row
        } else {
          row[cellIndex] = '';
        }
      });
    });

    slide.addTable(data, tableOptions);
    // kaizentype ended

    const rows = [
      [
        { text: 'BEFORE', options: { align: 'center', fontFace: 'Calibri', fill: { color: 'FFFF00' } } },
        { text: 'AFTER', options: { align: 'center', fontFace: 'Calibri', fill: { color: '92d050' } } },
      ],
      [
        { text: '', options: { align: 'center', fontFace: 'Calibri' } }, // Second row to be modified separately
        { text: '', options: { align: 'center', fontFace: 'Calibri' } },
      ],
      [
        { text: 'Observation:', options: { align: 'left', fontFace: 'Calibri' } },
        { text: 'Improvement', options: { align: 'left', fontFace: 'Calibri' } },
      ],
    ];


    const observation = beforeDescription; // Replace with your desired dynamic observation
    rows[2][0].text = 'Observation: ' + observation;

    const improvement = afterDescription; // Replace with your desired dynamic observation
    rows[2][1].text = 'Improvement: ' + improvement;

    const tableOpts = {
      x: (slide.width - 9) / 2,
      y: 0.9,
      w: 9,
      h: 12,
      rowH: [0.5, 2.5, 1],
      fontFace: 'Arial',
      valign: 'middle',
      fill: { color: 'FFFFFF' },
      color: '000000',
      fontSize: 12,
      bold: true,
      border: { pt: 1, color: '000000', type: 'solid' },
    };

    const table = slide.addTable(rows, tableOpts);

    if (table && Array.isArray(table.body)) {
      for (let i = 0; i < table.body.length; i++) {
        const row = table.body[i];
        for (let j = 0; j < row.length; j++) {
          const cell = row[j];
          cell.options.border = { pt: 1, color: '000000', type: 'solid' };
        }
      }
    }
  
    const imageCell1 = {
      x: tableOpts.x,
      y: 1.45,
      w: tableOpts.w / 2,
      h: tableOpts.rowH[1],
      path: imagePathBefore,
      sizing: {
        type: 'contain',
        w: tableOpts.w / 2,
        h: tableOpts.rowH[1],
      },
    };

    slide.addImage(imageCell1);
    const imageCell2 = {
      x: tableOpts.x + tableOpts.w / 2,
      y: 1.45, // Add another rowH[1] and rowH[0] to account for the second row's height and the first row's height
      w: tableOpts.w / 2,
      h: tableOpts.rowH[1],
      path: imagePathAfter,
      sizing: {
        type: 'contain',
        w: tableOpts.w / 2,
        h: tableOpts.rowH[1],
      },
    };

    slide.addImage(imageCell2);
    // Add an additional table at the bottom
    const bottomRows = [
      [
        { text: 'Kaizen No', options: { align: 'center', fontFace: 'Calibri', } },
        { text: getReportData.serial_number, options: { align: 'center', fontFace: 'Calibri', } },
      ],
    ];

    const bottomTableOpts = {
      x: 7.0,
      y: 5,
      w: 2.8, // Update width
      h: 0.03, // Update height
      rowH: [0.3],
      colW: [1, 1.5], // Set column widths (e.g., first column width = 1, second column width = 2)
      fontFace: 'Arial',
      valign: 'middle',
      fill: { color: 'FFFFFF' },
      color: '000000',
      fontSize: 10,
      bold: true,
      border: { pt: 1, color: '000000', type: 'solid' },
    };
    slide.addTable(bottomRows, bottomTableOpts);
    ppt.writeFile({ fileName: serialNumber });
  },
  sharePresentation(getReportData) {
    const beforeData = getReportData.before_status;
    const afterData = getReportData.after_status;

    let beforeImageName, beforeDescription = '';
    let afterImageName, afterDescription = '';

    if (beforeData) {
      let beforeImage = beforeData[0].before_image
      const imagesString = beforeImage.split(",");
      const jsonArray = JSON.parse(imagesString);
      const firstImage = jsonArray[0];
      beforeImageName = firstImage.name;
      beforeDescription = beforeData[0].description;
    }
    if (afterData) {
      let afterImage = afterData[0].after_image;
      const imagesString = afterImage.split(",");
      const jsonArray = JSON.parse(imagesString);
      const firstImage = jsonArray[0];
      afterImageName = firstImage.name;
      afterDescription = afterData[0].description;
    }
    const ppt = new pptxgen();
    const slide = ppt.addSlide();
    // Set slide properties to remove the space at the top
    slide.back = 'FFFFFF'; // Set slide background color to white
    slide.margin = [0, 0, 0, 0]; // Set slide margins to 0

    const imagePathBefore = beforeImageName;
    const imagePathAfter = afterImageName;
    console.log("imagePathBefore", imagePathBefore);
    console.log("imagePathAfter", imagePathAfter);

    slide.addText(
      'KAIZEN NAME',
      {
        x: 1,
        y: 0.1,
        w: '80%',
        h: 0.5,
        fontSize: 25,
        align: 'center',
        color: '008899',
      }
    );
    const tableOptions = {
      x: 6.5,
      y: 0.1,
      w: '30%',
      fill: 'FFFFFF',
      fontSize: 12,
      border: {
        pt: 1,
        color: '000000',
      },
      fillCell: (rowIndex, cellIndex) => {
        const headers = ['P', 'Q', 'C', 'D', 'S', 'M', 'S'];
        if (rowIndex === 0 && headers[cellIndex] && headers[cellIndex] !== 'S') {
          return '0000FF'; // Blue color for header cells
        }
        return null; // No background color for other cells
      }
    };

    const data = [
      ['P', 'Q', 'C', 'D', 'S', 'M', 'S'],
      ['Productivity', 'Quality', 'Cost', 'Delivery', 'Safety', 'Moral', 'Standards']
    ];
    const shouldShowTickMark = ['Productivity', 'Quality', 'Cost', 'Delivery', 'Safety', 'Moral', 'Standards'].includes(getReportData.kaizen_type);
    const tickSymbol = shouldShowTickMark ? '\u2713' : '';

    // Iterate over each row in the data array
    data.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        if (rowIndex === 0) {
          if (cellIndex === row.length - 1) {
            row[cellIndex] = { text: cell, options: { fill: { color: '92d050' }, font: { color: 'FFFFFF' } } }; // Green color for last cell in the first row
          } else {
            row[cellIndex] = { text: cell, options: { fill: { color: '4472C4' }, font: { color: 'FFFFFF' } } }; // Blue color for other cells in the first row
          }
        } else if (rowIndex === 1 && [getReportData.kaizen_type].includes(cell)) {
          row[cellIndex] = { text: shouldShowTickMark ? tickSymbol : '', options: { fill: { color: 'FFFFFF' }, font: { color: 'FFFFFF' } } }; // Yellow color and white font for specified kaizen_type cells in the second row
        } else {
          row[cellIndex] = '';
        }
      });
    });

    slide.addTable(data, tableOptions);
    // kaizentype ended

    const rows = [
      [
        { text: 'BEFORE', options: { align: 'center', fontFace: 'Calibri', fill: { color: 'FFFF00' } } },
        { text: 'AFTER', options: { align: 'center', fontFace: 'Calibri', fill: { color: '92d050' } } },
      ],
      [
        { text: '', options: { align: 'center', fontFace: 'Calibri' } }, // Second row to be modified separately
        { text: '', options: { align: 'center', fontFace: 'Calibri' } },
      ],
      [
        { text: 'Observation:', options: { align: 'left', fontFace: 'Calibri' } },
        { text: 'Improvement', options: { align: 'left', fontFace: 'Calibri' } },
      ],
    ];


    const observation = beforeDescription; // Replace with your desired dynamic observation
    rows[2][0].text = 'Observation: ' + observation;

    const improvement = afterDescription; // Replace with your desired dynamic observation
    rows[2][1].text = 'Improvement: ' + improvement;

    const tableOpts = {
      x: (slide.width - 9) / 2,
      y: 0.9,
      w: 9,
      h: 12,
      rowH: [0.5, 2.5, 1],
      fontFace: 'Arial',
      valign: 'middle',
      fill: { color: 'FFFFFF' },
      color: '000000',
      fontSize: 12,
      bold: true,
      border: { pt: 1, color: '000000', type: 'solid' },
    };

    const table = slide.addTable(rows, tableOpts);

    if (table && Array.isArray(table.body)) {
      for (let i = 0; i < table.body.length; i++) {
        const row = table.body[i];
        for (let j = 0; j < row.length; j++) {
          const cell = row[j];
          cell.options.border = { pt: 1, color: '000000', type: 'solid' };
        }
      }
    }

    try {
      const imageCell1 = {
        x: tableOpts.x,
        y: 1.45,
        w: tableOpts.w / 2,
        h: tableOpts.rowH[1],
        path: imagePathBefore,
        sizing: {
          type: 'contain',
          w: tableOpts.w / 2,
          h: tableOpts.rowH[1],
        },
      };

      slide.addImage(imageCell1);
    } catch (error) {
      console.error('Error loading image:', error);
    }

    const imageCell2 = {
      x: tableOpts.x + tableOpts.w / 2,
      y: 1.45, // Add another rowH[1] and rowH[0] to account for the second row's height and the first row's height
      w: tableOpts.w / 2,
      h: tableOpts.rowH[1],
      path: imagePathAfter,
      sizing: {
        type: 'contain',
        w: tableOpts.w / 2,
        h: tableOpts.rowH[1],
      },
    };

    slide.addImage(imageCell2);
    // Add an additional table at the bottom
    const bottomRows = [
      [
        { text: 'Kaizen No', options: { align: 'center', fontFace: 'Calibri', } },
        { text: getReportData.serial_number, options: { align: 'center', fontFace: 'Calibri', } },
      ],
    ];

    const bottomTableOpts = {
      x: 7.0,
      y: 5,
      w: 2.8, // Update width
      h: 0.03, // Update height
      rowH: [0.3],
      colW: [1, 1.5], // Set column widths (e.g., first column width = 1, second column width = 2)
      fontFace: 'Arial',
      valign: 'middle',
      fill: { color: 'FFFFFF' },
      color: '000000',
      fontSize: 10,
      bold: true,
      border: { pt: 1, color: '000000', type: 'solid' },
    };
    slide.addTable(bottomRows, bottomTableOpts);

    // Save the presentation as a zip binary data
    const zip = ppt.writeFile({ returnType: 'blob' });
    // Assuming you have the binary data in a Promise 'zip'
    zip.then(function (binaryData) {
      // Create a Blob object from the binary data with the appropriate MIME type
      const blob = new Blob([binaryData], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      });

      // Create FormData and append Blob
      const formData = new FormData();
      formData.append('file', blob, 'Presentation.pptx');
      axios.post(Services.FILE_UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(function (response) {
          console.log('Response for PPTX', response.data);
        })
        .catch(function (error) {
          console.log('ERROR', error);
        });
    });
  },

  async generateExcel(exceldata) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
  
    // Add data to the worksheet
    const headerRow = worksheet.addRow([
      'Si No.',
      'Employee ID',
      'Employee Name',
      'Kaizen Number',
      'Plant',
      'Work center',
      'Category',
      'Kaizen Title',
      'Kaizen Date',
      'Status'
    ]);
    headerRow.font = { bold: true };
  
    // Batch API Calls
    let counter = 1;
    const apiPromises = exceldata.map(async (data) => {       
      console.log("datadata", data);
      const rowData = [
        counter++, // Si No.
        data ? data.user_employee_id : 'N/A',        // Employee ID
        data ? `${data.user_first_name} ${data.user_last_name}` : 'N/A', // Employee Name
        data ? data.serial_number : 'N/A', // Plant
        data ? data.plant_name : 'N/A', // Plant
        data ? data.work_center_name : 'N/A', // Work center
        data ? data.kaizen_type : 'N/A', // Category
        data ? data.kaizen_name : 'N/A',   // Kaizen Title
        data ? data.opening_date : 'N/A',   // Kaizen Date
        'Approved'                
      ];
  
      worksheet.addRow(rowData);
      return rowData;
    });
  
    // Wait for all API calls to complete
    const dataRows = await Promise.all(apiPromises);
  
    // Set column widths based on content
    worksheet.columns.forEach((column, colIndex) => {
      let maxLength = 0;
      dataRows.forEach((rowData) => {
        const cellText = String(rowData[colIndex]);
        maxLength = Math.max(maxLength, cellText.length);
      });
  
      column.width = Math.max(10, maxLength + 2);
    });
  
    // Generate a blob from the workbook
    const blob = await workbook.xlsx.writeBuffer();
  
    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([blob]));
  
    // Create a link and trigger a click event to download the file
    const a = document.createElement('a');
    a.href = url;
    a.download = `${new Date()}.xlsx`;
    a.click();
  
    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  }
}
export default Helper;