import React, { useState, useEffect, useRef } from 'react';
import '../../styles.css';
import { useRecoilState } from 'recoil';
import CustomSelect from '../../input/CustomSelect';
import { Typography, } from "@mui/material";
import axios from 'axios';
import { Button ,CircularProgress} from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import CloudUpload from '../../assets/icons/ic_cloud_upload.svg';
import Services from '../../services/Services';
import { imageType, isUpdateBeforeStatus, beforeImageDescriptionState, newKaizenUpdatestate } from "../../recoil/atoms";
import Text from '../../input/Text';
import API from '../../services/API';
import SuccessDialog from '../../input/SuccessDialog';
import AlertDialog from '../../input/AlertDialog';


const Home = () => {
  const navigate = useNavigate();
  const [locationList, setLocationList] = useState([]);
  const [workCenterList, setWorkCenterList] = useState([]);
  const [kaizenList, setKaizenList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedKaizenType, setSelectedKaizenType] = useState('');
  const [selectedWorkCenter, setSelectedWorkCenter] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [, setimageValue] = useRecoilState(imageType);
  const [, setIsUpdate] = useRecoilState(isUpdateBeforeStatus);
  const [kaizenName, setKaizenName] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const fileInputRef = useRef(null);
  const [beforeImageDescription, setBeforeImageDescription] = useRecoilState(beforeImageDescriptionState);
  const [, setNewKaizenUpdate] = useRecoilState(newKaizenUpdatestate);
  const [isLoading, setIsLoading] = useState(false);
  const [plantName, setPlantName] = useState('');
  const [workCenterName, setWorkCenterName] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [serialNumber, setSerialNumber] = useState(false);



  useEffect(() => {
    const fetchData = async () => {
      await getLocationDetails();
      await getKaizenTypeDetails();
      setBeforeImageDescription("");
      setNewKaizenUpdate({});
    };
    fetchData();
  }, []);

  const handlePopover = (event) => {
    event.preventDefault();
    const errors = {};
    if (kaizenName.trim() === '') {
      errors.kaizenName = 'Kaizen Name is required';
    }
    if (selectedLocation === '') {
      errors.selectedLocation = 'Location is required';
    }
    if (selectedWorkCenter === '') {
      errors.selectedWorkCenter = 'Work center is required';
    }
    if (selectedKaizenType === '') {
      errors.selectedKaizenType = 'Kaizen Type is required';
    }
    if (beforeImageDescription.trim() === '') {
      errors.beforeImageDescription = 'Observation is required';
    }
      // If there are no validation errors, perform the save action
    if (Object.keys(errors).length === 0) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      setFormErrors(errors);
    } 
  };


  const handleKaizenNameChange = (event) => {
    setKaizenName(event.target.value);
  }


  const handleChangeBeforeImageDescription = (event) => {
    setBeforeImageDescription(event.target.value);
  };

  const handleFileSelect = (event) => {
    const files = event.target.files; // Get the selected files from the input
    const maxAllowedFiles = 4;
    // Check if the number of selected files exceeds the maximum allowed
    if (files.length > maxAllowedFiles) {
      alert(`You can only select up to ${maxAllowedFiles} images.`);
      // Clear the file input to prevent uploading more files
      fileInputRef.current.value = null;
    } else {
      // Process the selected files here
      const imageUrls = []; // Array to store the URLs of selected images

  // Example: Display the selected images
        for (const file of files) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const imageUrl = e.target.result;
            imageUrls.push(imageUrl); // Store the URL in the array
            // Display the image
            const image = new Image();
            image.src = imageUrl;
            document.body.appendChild(image);
            // Log the first image URL
            console.log("Selected image URLs:", imageUrls[0]);
            setimageValue(imageUrls[0]);
            setIsUpdate(false);
            kaizenObject();
            navigate(`/capturedimage`);
          };
          reader.readAsDataURL(file); // Read the selected file as a data URL
        }

    }

  };

  const saveAllUserData = async () => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const serialNumber = await generateSerialNumber(); // Await the promi
      const formData = new FormData();
      formData.append('kaizen_name', kaizenName);
      formData.append('plant_id', selectedLocation);
      formData.append('work_center_id', selectedWorkCenter);
      formData.append('opening_date', selectedDate);
      formData.append('kaizen_type', selectedKaizenType);
      formData.append('user_id', userData.id);
      formData.append('serial_number', serialNumber);
      formData.append('updated_at', new Date().toISOString());
      formData.append('observation', beforeImageDescription);
      const response = await axios.post(Services.INSERT_NEW_KAIZEN, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });     
      if (response.status === 200) {
        console.log("RESPONSEEE", response);
        setSerialNumber(response.data.serial_number);
        setShowDialog(true);
      }else if(response.status === 401){
        console.error("Unauthorized");
      }else{
        console.error("Server Error")
      }
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  }

  const getLatestRecord = async () => {
    try {
      setIsLoading(true);
      const latestRecord = await API.getLatestKaizen(userData);

      if (!latestRecord) {
        setIsLoading(false);
        return "001"; // No latest record, default to "001"
      }
      let serialNumber = latestRecord.serial_number;
      const getMonthfromSerial = serialNumber.split("/")[2].substring(0, 2);
      let todayDate = new Date();
      const dateOnly = todayDate.toISOString().split("T")[0];
      const monthOnly = dateOnly.split("-")[1]; // Extract only the month
      if (monthOnly.includes(getMonthfromSerial)) {
        const n = serialNumber.slice(-3);
        let incrementedNumber = parseInt(n) + 1;
        let number = String(incrementedNumber).padStart(3, '0');
        console.log("numbernumber", number);
        setIsLoading(false);
        return number;
      } else {
        setIsLoading(false);
        return "001";
      }
    } catch (error) {
      console.log("ERROR", error);
      setIsLoading(false);
    }
  };
  async function generateSerialNumber() {
    const lastThreeDigits = await getLatestRecord();
    const date = new Date();
    const month = (date.getMonth() + 1).toString().padStart(2, 0);
    const year = date.getFullYear().toString().slice(-2);
    const dateWithSerialNumber = month + year + "_" + lastThreeDigits;
    try {
      setIsLoading(true);
      const getFinalPlantName = plantName.slice(0, 3).toUpperCase();
      const number = getFinalPlantName + "/" + workCenterName + "/" + dateWithSerialNumber;
      setIsLoading(false);
      return number;
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred:", error);
    }
  }


  const handleClick = () => {
    const errors = {};
    if (kaizenName.trim() === '') {
      errors.kaizenName = 'Kaizen Name is required';
    }
    if (selectedLocation === '') {
      errors.selectedLocation = 'Location is required';
    }
    if (selectedWorkCenter === '') {
      errors.selectedWorkCenter = 'Work center is required';
    }
    if (selectedKaizenType === '') {
      errors.selectedKaizenType = 'Kaizen Type is required';
    }
    if (beforeImageDescription.trim() === '') {
      errors.beforeImageDescription = 'Observation is required';
    }
    setFormErrors(errors);
    // If there are no validation errors, perform the save action
    if (Object.keys(errors).length === 0) {
      saveAllUserData();
    }
  }

  const handleLocationChange = (selectedValue, selectedName) => {
    setSelectedLocation(selectedValue);
    getWorkCenterDetails(selectedValue);
    setPlantName(selectedName);
  };

  const handleWorkCenterChange = (selectedValue, selectedName) => {
    setSelectedWorkCenter(selectedValue);
    setWorkCenterName(selectedName);
  };
  const handleKaizenChange = (selectedValue) => {
    setSelectedKaizenType(selectedValue);
  };

  const handleDateSelection = (value, type) => {
    if (type === "from") {
      setSelectedDate({ ...selectedDate, from: value });
    } else if (type === "to") {
      setSelectedDate({ ...selectedDate, to: value });
    }
  };


  const kaizenObject = () => {
    const obj = {
      kaizen_type: selectedKaizenType,
      plant_name: plantName,
      work_center_name: workCenterName,
      plant_id: selectedLocation,
      opening_date: selectedDate,
      work_center_id: selectedWorkCenter,
      kaizen_name: kaizenName,
      observation: beforeImageDescription,
    }
    console.log("kaizenObject", obj);
    const jsonString = JSON.stringify(obj);
    localStorage.setItem('kaizenData', jsonString)
  }

  const getLocationDetails = async () => {
    try {
      setIsLoading(true);
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_ALL_PLANTS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }
      });
      if (response.status === 200) {
        if (response.data.plant) {
          const filteredPlantList = response.data.plant.filter((plant) => plant.plant !== "All");
          console.log("filteredPlantList", filteredPlantList);
          if (userData.role_id === 3 || userData.role_id === 4 || userData.role_id === 5) {
            const filteredData = filteredPlantList.filter((item) => item.id === userData.plant_id);
            setLocationList(filteredData);
          } else {
            setLocationList(filteredPlantList);
          }
        }
      } else {
        console.log("servererror");
      }
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
      setIsLoading(false);
    }
  };

  const getWorkCenterDetails = async (id) => {
    try {
      setIsLoading(true);
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;

      const response = await axios.get(`${Services.GET_WORK_CENTER_NAME}?plant_id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
            if (response.status === 200) {
        if (response.data.data.work_center_name) {
          setWorkCenterList(response.data.data.work_center_name);
        }
      } else {
        console.log("servererror");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
      console.log("ERROR", error);
    }
  };

  const getKaizenTypeDetails = async () => {
    try {
      setIsLoading(true);
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_KAIZEN_TYPE, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }
      });
      if (response.status === 200) {
        if (response.data.data.kaizen_type) {
          setKaizenList(response.data.data.kaizen_type);
        }
      } else {
        console.log("servererror");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  };
  

  const handleCancelClick = () => {
    navigate(`/kaizen`);
  }
  const onHandleClosed = () => {
    setShowDialog(false);
    navigate(`/kaizen`);
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handlePopover();
    }
  };


  return (
    <div style={{ padding: 10, width: "100%", overflow: "hidden" }}>
      <SuccessDialog open={showDialog} onClose={onHandleClosed} msg={`New Kaizen is created with the Serial Number ${serialNumber}`} />
      {isLoading && <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Make the loader cover the entire viewport height
          width: '100vw', // Make the loader cover the entire viewport width
          background: 'rgba(255, 255, 255,0.3)', // Add a semi-transparent background
          zIndex: 9999, // Set a high z-index to ensure it's on top of everything
        }}> <CircularProgress /></div>}
      <div>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600, marginTop: 10 }}>Before Kaizen</Typography>
        <Typography style={{ textAlign: 'left' }}>Prior to entering Sekurit India, Kaizen</Typography>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Text
          style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
          id="outlined-read-only-input"
          label="Kaizen Name"
          value={kaizenName}
          variant='outlined'
          InputLabelProps={{
            shrink: true
          }}
          onChange={handleKaizenNameChange}
          error={formErrors.kaizenName}
          helperText={formErrors.kaizenName}
        />
      </div>


      <div style={{ marginTop: "30px" }}>
        <CustomSelect
          label="Location"
          value={selectedLocation}
          onChange={handleLocationChange}
          list={locationList}
          error={formErrors.selectedLocation}
          helperText={formErrors.selectedLocation}
          name="plant"
          id="id"
        />
      </div>

      <div style={{ marginTop: "30px" }}>
        <CustomSelect
          label="Work Center"
          value={selectedWorkCenter}
          onChange={handleWorkCenterChange}
          list={workCenterList}
          error={formErrors.selectedWorkCenter}
          helperText={formErrors.selectedWorkCenter}
          name="wc_name"
          id="id"
        />
      </div>


      <div style={{ marginTop: "30px" }}>
        <CustomSelect
          label="Kaizen Type"
          value={selectedKaizenType}
          onChange={handleKaizenChange}
          list={kaizenList}
          error={formErrors.selectedKaizenType}
          helperText={formErrors.selectedKaizenType}
          name="name"
          id="id"
        />
      </div>

      <div style={{ marginTop: "30px" }}>
        <Text
          style={{ width: "100%", marginTop: 30, textAlign: "left" }}
          id="date"
          label="Date"
          type="date"
          variant="outlined"
          value={selectedDate}
          onChange={(event) => handleDateSelection(event.target.value, "from")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div style={{ marginTop: "30px" }}>
        <Text
          required
          fullWidth
          label="Observation"
          name="description"
          autoComplete="off"
          value={beforeImageDescription}
          onChange={handleChangeBeforeImageDescription}
          multiline={true}
          rows={5}
          error={formErrors.beforeImageDescription}
          helperText={formErrors.beforeImageDescription}
        />
      </div>
      <div style={{
        border: "2px dashed #00448B",
        padding: "20px",
        flex: 1,
        display: 'flex',
        width: "100%",
        height: 300,
        marginTop: 30,
        backgroundColor: '#00448a1a',
        marginRight: 20,
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "4px"
      }}>
        <button
          onClick={handlePopover}
          onKeyDown={handleKeyPress}
          style={{
            width: 30,
            height: 30,
            marginLeft: 10,
            marginBottom: 10,
            cursor: 'pointer',
            padding: 0,  // Remove padding to ensure image is not covered
            border: 'none',  // Remove border to ensure image is not covered
            background: 'none',  // Remove background to ensure image is not covered
          }}
          aria-label="Upload File"
        >
          <img
            src={CloudUpload}
            alt="Upload File"
            style={{ width: '100%', height: '100%' }}
          />
        </button>

        <Typography onClick={handlePopover} style={{
          textAlign: 'center',
          marginLeft: 15,
          color: 'black',
          fontWeight: 600,
          width: 170,
          fontSize: 16
        }}>Click to Capture/Upload</Typography>

        <input
          type="file"
          ref={fileInputRef} // Assign the input element to the ref
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          accept="image/*" 
        />
      </div>



      <div style={{ display: 'grid', gridTemplateColumns: '32% 32% 32%', width: "100%", marginTop: 30, overflow: 'auto', justifyContent: 'space-between', minHeight: '100px' }}>
        <div style={{
          border: "2px dashed #00448B",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: "4px",
        }}>
          <img src={CloudUpload} style={{
            width: '25px',
            height: '25px',
          }} alt="My Image" />
        </div>
        <div style={{
          border: "2px dashed #00448B",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: "4px",
        }}>
          <img src={CloudUpload} style={{
            width: '25px',
            height: '25px',
          }} alt="My Image" />
        </div>
        <div style={{
          border: "2px dashed #00448B",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: "4px",
        }}>
          <img src={CloudUpload} style={{
            width: '25px',
            height: '25px',
          }} alt="My Image" />
        </div>

      </div>



      <div style={{ width: '95%', display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <Button
          variant="outlined"
          style={{
            borderRadius: '3px',
            width: "47%",
            marginLeft: 20,
            marginRight: 20,
            flex: 1,
            display: 'flex',
            borderWidth: '3px',
            border: '2px solid #00448B',
            fontSize: 20,
            color: "#00448B",
            marginTop: "10px",
            textTransform: 'none',
            height: "45px",
          }}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            width: "48%",
            borderRadius: '3px',
            flex: 1,
            display: 'flex',
            backgroundColor: "#00448B",
            fontSize: 20,
            color: "white",
            marginTop: "10px",
            textTransform: 'none',
            height: "45px",
          }}
          onClick={handleClick}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
export default Home;
