import React, { useState, useEffect } from 'react';
import { TextField, Button } from "@mui/material";
import axios from 'axios';
import Services from '../../services/Services';
import { MenuItem, } from '@material-ui/core';
import SuccessDialog from '../../input/SuccessDialog';
import { CircularProgress } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertDialog from '../../input/AlertDialog';

const NewUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = location.state ? location.state.userInfo : null;
  const isEdit = location.state ? location.state.isEdit : null;
  const initialFirstName = userInfo ? userInfo.first_name : '';
  const initialLastName = userInfo ? userInfo.last_name : '';
  const initialSGID = userInfo ? userInfo.sgid : '';
  const initialEmail = userInfo ? userInfo.email_id : '';
  const initialRole = userInfo ? userInfo.role_id : '';
  const initialBusiness = userInfo ? userInfo.business_id : '';
  const initialEmpID = userInfo ? userInfo.employee_id : '';
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [roleList, setRoleList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [empID, setEmpID] = useState(initialEmpID);
  const [sgid, setSgid] = useState(initialSGID);
  const [email, setEmail] = useState(initialEmail);
  const [selectedRole, setSelectedRole] = useState(initialRole);
  const [selectedBusiness, setSelectedBusiness] = useState(initialBusiness);
  let initialPlant;
  if (userInfo) {
    if (userInfo.role_id === 1 || userInfo.role_id === 2) {
      initialPlant = 4;
    } else {
      initialPlant = userInfo.plant_id;
    }
  } else {
    initialPlant = '';
  }
  const [selectedPlant, setSelectedPlant] = useState(initialPlant);
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (userInfo) {
      getPlantDetails(userInfo.business_id);
    }
  }, []);


  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  }

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  }

  const handleEmpID = (event) => {
    setEmpID(event.target.value);
  }
  const handleSgIdChange = (event) => {
    setSgid(event.target.value);
  }
  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }
  const handleCancelClick = () => {
    navigate(-1);
  }

  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@(ext\.)?saint-gobain\.com$/;
    return regex.test(email);
  };


  useEffect(() => {
    getRoleDetails();
    getBusinessDetails();
  }, []);

  const handleSaveClick = () => {
    const errors = {};

    if (firstName === null || firstName.trim() === '') {
      errors.firstName = 'First Name is required';
    }

    if (lastName === null || lastName.trim() === '') {
      errors.lastName = 'Last Name is required';
    }

    if (empID === null) {
      errors.empID = 'Employee Id is required';
    }

    if (sgid === null || sgid.trim() === '') {
      errors.sgid = 'SG ID is required';
    }

    // Update the email validation logic
    if (email && !validateEmail(email)) {
      errors.email = 'Invalid E-Mail format';
    }

    if (selectedRole === '' || selectedRole === null) {
      errors.selectedRole = 'Role is required';
    }
    if (selectedBusiness === '' || selectedBusiness === null) {
      errors.selectedBusiness = 'Business is required';
    }
    if (selectedPlant === '' || selectedPlant === null) {
      errors.selectedPlant = 'Plant is required';
    }

    setFormErrors(errors);

    // If there are no validation errors, perform the save action
    if (Object.keys(errors).length === 0) {
      const formData = new FormData();
      const roleId = selectedRole;
      const plantId = selectedPlant;
      const businessId = selectedBusiness;
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('sgid', sgid);
      formData.append('email_id', email);
      formData.append('role_id', roleId);
      formData.append('business_id', businessId);
      formData.append('plant_id', plantId);
      formData.append('updated_at', new Date().toISOString());
      formData.append('created_by', userData.id);
      formData.append('employee_id', empID);
      // formData.append('work_center_id', selectedWorkCenter);
      // Call updateUser function with the id and new data
      if (userInfo) {
        updateUser(formData)
          .then(response => {
            setIsUserUpdated(true);
            console.log("response", response); // User updated successfully
          })
          .catch(error => {
            console.error('Failed to update user:', error.message);
          });
      } else {
        insertUser(formData)
          .then(response => {
            setIsUserUpdated(false);
            console.log("response", response);
          })
          .catch(error => {
            console.error('Failed to insert user:', error.message);
          });
      }

    }
  }
  const updateUser = async (newData) => {
    try {
      setLoading(true); // Set loading to true before making the API call
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.put(`${Services.UPDATE_USER}${userData.sgid}?condition=true`, newData, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      setShowDialog(true);
      return response.data;
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        setError(error.message);
        console.log("Other error:", error);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const insertUser = async (data) => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      setLoading(true); // Set loading to true before making the API call
      const response = await axios.post(`${Services.INSERT_USER}${userData.sgid}?condition=true`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      setShowDialog(true);
      return response.data;
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
        setError(error.message);

      }
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const getRoleDetails = async () => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_ROLES, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      console.log("roles", response);
      if (response.status === 200) {
        if (response.data?.roles) {
          const roles = response.data.roles;
          setRoleList(roles);
        }
      } else {
        console.log("servererror");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const getBusinessDetails = async () => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_BUSINESS, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      // handle success
      if (response.status === 200) {
        if (response.data?.business) {
          const business = response.data.business;
          setBusinessList(business);
        }
        else if (response.status === 401) {
          console.error("Unauthorized");
        } else {
          console.error("Server Error")
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleBusinessList = (event) => {
    setSelectedBusiness(event.target.value);
    setSelectedPlant(null);
    getPlantDetails(event.target.value)
  };
  const handlePlantList = (event) => {
    setSelectedPlant(event.target.value);
  }
  const handleRoleList = (event) => {
    setSelectedRole(event.target.value);
  }
  const onHandleClosed = () => {
    setShowDialog(false);
    navigate(`/usermanagement`);
  }

  const getPlantDetails = async (id) => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;

    try {
      const response = await axios.get(`${Services.GET_PLANT}?business_id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key: secret_key
        }
      });

      if (response.status === 200) {
        if (response && response.data.plant) {
          setPlantList(response.data.plant);
        }
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };


  return (
    <div>
      {userData.role_id === 1 || userData.role_id === 5 ? (
        <div>
          {error && <div>{error}</div>}
          <div style={{ padding: 10 }}>
            <SuccessDialog open={showDialog} onClose={onHandleClosed} msg={isUserUpdated ? "User Updated Successfully" : "User Inserted Successfully"} />
            <div>
              <TextField
                style={{ width: "100%", marginTop: 30 }}
                id="name"
                label="First Name"
                value={firstName}
                onChange={handleFirstNameChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  border: 'red',
                }}
                error={formErrors.firstName ? true : false}
                helperText={formErrors.firstName}
              />
            </div>
            <div>
              <TextField
                style={{ width: "100%", marginTop: 30 }}
                id="name"
                label="Last Name"
                value={lastName}
                onChange={handleLastNameChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  border: 'red',
                }}
                error={formErrors.lastName ? true : false}
                helperText={formErrors.lastName}
              />
            </div>

            <div>
              <TextField
                style={{ width: "100%", marginTop: 30 }}
                id="empid"
                label="Employee ID"
                value={empID}
                onChange={handleEmpID}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  border: 'red',
                }}
                error={formErrors.empID ? true : false}
                helperText={formErrors.empID}
              />
            </div>

            {/* Rest of the form fields */}
            <div>
              <TextField
                style={{ width: "100%", marginTop: 30 }}
                id="sgid"
                label="SG ID"
                value={sgid}
                onChange={handleSgIdChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  border: 'red'
                }}
                error={formErrors.sgid ? true : false}
                helperText={formErrors.sgid}
                disabled={isEdit}
              >
              </TextField>
            </div>

            <div>
              <TextField
                style={{ width: "100%", marginTop: 30, }}
                id="emaild"
                label="E-Mail Id"
                value={email}
                onChange={handleEmailChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  border: 'red'
                }}
                error={formErrors.email ? true : false}
                helperText={formErrors.email}
              />
            </div>
            <div>

              {/* started we are checking 3 conditions for wcm coordinator edit true with two conditions otherwise one condition */}

              <div>
                {userData.role_id === 5 && isEdit === false ? (
                  // Render this when userData.role_id is 5
                  <TextField
                    style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
                    id="outlined-select-currency"
                    select
                    label="Role"
                    value={selectedRole}
                    onChange={handleRoleList}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={formErrors.selectedRole ? true : false}
                    helperText={formErrors.selectedRole}
                  >
                    {roleList.length ? (
                      roleList
                        .filter((d) => d.role !== "Admin" && d.role !== "WCM Coordinator" && d.role !== "Plant Manager")
                        .map((d) => (
                          <MenuItem key={d.id} value={d.id}>
                            {d.role}
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem value={null}>None</MenuItem>
                    )}
                  </TextField>
                ) : (
                  <div>
                    {userData.role_id === 5 && userInfo.role_id === 5 && isEdit === true ? (
                      <TextField
                        style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
                        id="outlined-select-currency"
                        select
                        label="Role"
                        value={selectedRole}
                        onChange={handleRoleList}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={formErrors.selectedRole ? true : false}
                        helperText={formErrors.selectedRole}
                        disabled={userData.role_id === 5 && userInfo.role_id === 5}
                      >
                        {roleList.length ? (
                          roleList.map((d) => (
                            <MenuItem key={d.id} value={d.id}>
                              {d.role}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={null}>None</MenuItem>
                        )}
                      </TextField>
                    ) : (
                      // If conditions are not met, display another text field or any other content.
                      <div>
                        {userData.role_id === 5 && userInfo.role_id !== 5 && isEdit ? (

                          <TextField
                            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
                            id="outlined-select-currency"
                            select
                            label="Role"
                            value={selectedRole}
                            onChange={handleRoleList}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={formErrors.selectedRole ? true : false}
                            helperText={formErrors.selectedRole}
                            disabled={userData.role_id === 5 && userInfo.role_id === 5}
                          >
                            {roleList.length ? (
                              roleList
                                .filter((d) => d.role !== "Admin" && d.role !== "WCM Coordinator" && d.role !== "Plant Manager")
                                .map((d) => (
                                  <MenuItem key={d.id} value={d.id}>
                                    {d.role}
                                  </MenuItem>
                                ))
                            ) : (
                              <MenuItem value={null}>None</MenuItem>
                            )}
                          </TextField>
                        ) : null}
                      </div>

                    )}
                  </div>
                )}
              </div>

              {userData.role_id === 1 || userData.role_id === 2 || userData.role_id === 3 || userData.role_id === 4 ? (
                <TextField
                  style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
                  id="outlined-select-currency"
                  select
                  label="Role"
                  value={selectedRole}
                  onChange={handleRoleList}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formErrors.selectedRole ? true : false}
                  helperText={formErrors.selectedRole}
                >
                  {roleList.length ? (
                    roleList.map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.role}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={null}>None</MenuItem>
                  )}
                </TextField>
              ) : null}

              {/* endeddd  */}

              <TextField
                style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
                id="outlined-select-currency"
                select
                label="Business"
                value={selectedBusiness}
                onChange={handleBusinessList}
                InputLabelProps={{
                  shrink: true,
                }}
                error={formErrors.selectedBusiness ? true : false}
                helperText={formErrors.selectedBusiness}>
                {businessList.length ? (
                  businessList.map((d) => (
                    <MenuItem key={d.id} value={d.id}>
                      {d.business}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={null}>None</MenuItem>
                )}
              </TextField>

              <div>
                <TextField
                  style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
                  id="outlined-select-currency"
                  select
                  label="Plant"
                  value={selectedPlant}
                  onChange={handlePlantList}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formErrors.selectedPlant ? true : false}
                  helperText={formErrors.selectedPlant}
                >
                  {userData.role_id === 5 ? (
                    // Render this when userData.role_id is 5
                    plantList
                      .filter((d) => d.id === userData.plant_id)
                      .map((d) => (
                        <MenuItem key={d.id} value={d.id}>
                          {d.plant}
                        </MenuItem>
                      ))
                  ) : (
                    // Render this when userData.role_id is not 5
                    selectedRole === 1 || selectedRole === 2 ? (
                      <MenuItem value={4}>
                        {'All ('}
                        {plantList
                          .filter((d) => d.plant !== "All")
                          .map((d) => d.plant)
                          .join(', ')}
                        {')'}
                      </MenuItem>
                    ) : (
                      // Show only the "All" option
                      plantList
                        .filter((d) => d.plant !== "All")
                        .map((d) => (
                          <MenuItem key={d.id} value={d.id}>
                            {d.plant}
                          </MenuItem>
                        ))
                    )
                  )}
                </TextField>
              </div>


              {/* <div>
            {userData.role_id === 5 ? (
              // Render this when userData.role_id is 5
              <TextField
                style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
                id="outlined-select-currency"
                select
                label="Plant"
                value={selectedPlant}
                onChange={handlePlantList}
                InputLabelProps={{
                  shrink: true,
                }}
                error={formErrors.selectedPlant ? true : false}
                helperText={formErrors.selectedPlant}

              >
                {plantList.length ? (
                  plantList
                    .filter((d) => d.id === userData.plant_id)
                    .map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.plant}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem value={null}>None</MenuItem>
                )}
              </TextField>
            ) : (
              // Render the default TextField when userData.role_id is not 5
              <TextField
                style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
                id="outlined-select-currency"
                select
                label="Plant"
                value={selectedPlant}
                onChange={handlePlantList}
                InputLabelProps={{
                  shrink: true,
                }}
                error={formErrors.selectedPlant ? true : false}
                helperText={formErrors.selectedPlant}
              >
                {selectedRole === 1 || selectedRole === 2 ? (
                  <MenuItem value={4}>
                    {'All ('}
                    {plantList
                      .filter((d) => d.plant !== "All")
                      .map((d) => d.plant)
                      .join(', ')}
                    {')'}
                  </MenuItem>

                  // // Show all options in plantList
                  // <MenuItem value={4}>All </MenuItem>

                ) : (
                  // Show only the "All" option
                  plantList
                    .filter((d) => d.plant !== "All")
                    .map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.plant}
                      </MenuItem>
                    ))
                )}
              </TextField>
            )}
          </div> */}
            </div>


            <div style={{ width: '95%', display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
              <Button
                variant="outlined"
                style={{
                  borderRadius: '3px',
                  width: "47%",
                  marginLeft: 20,
                  marginRight: 20,
                  flex: 1,
                  display: 'flex',
                  borderWidth: '3px',
                  border: '2px solid #00448B',
                  fontSize: 20,
                  color: "#00448B",
                  marginTop: "10px",
                  textTransform: 'none',
                  height: "45px",
                }}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  width: "48%",
                  borderRadius: '3px',
                  flex: 1,
                  display: 'flex',
                  backgroundColor: "#00448B",
                  fontSize: 20,
                  color: "white",
                  marginTop: "10px",
                  textTransform: 'none',
                  height: "45px",
                }}
                onClick={handleSaveClick}>
                Save
              </Button>
            </div>
          </div>
          {loading && (
            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(0, 0, 0, 0.5)' }}>
              <CircularProgress />
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <p>You dont have access, please contact to Admin</p>
        </div>
      )}
    </div>
  );
  
}
export default NewUser;