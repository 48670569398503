import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Button
} from "@mui/material";
import { useRecoilState } from 'recoil';
import { useNavigate, useLocation } from "react-router-dom";
import { afterImageDescriptionState, imageType } from "../../recoil/atoms";
import ImageSlider from "../../input/ImageSlider";
import axios from 'axios';
import Services from '../../services/Services';
import AlertDialog from '../../input/AlertDialog';
import Text from '../../input/Text';
import CloudUpload from '../../assets/icons/ic_cloud_upload.svg';


const AfterKaizenSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);
  const [parsedData, setParsedData] = useState(null);
  const [description, setDescription] = useState(null);
  const [error, setError] = useState('');
  const [afterImageDescription, setAfterImageDescription] = useRecoilState(afterImageDescriptionState);
  const [imageValue, setImageValue] = useRecoilState(imageType);
  const recordData = location.state ? location.state.selectedRecordData : null;
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (recordData.before_status && recordData.before_status.length > 0) {
      const beforeData = recordData.before_status[0];
      const parsedData = JSON.parse(beforeData.before_image);
      const description = beforeData.description;
      setParsedData(parsedData);
      setDescription(description);
    } else {
      console.error('before_status is undefined or empty');
    }
  }, [recordData]);

  const handlePopover = (event) => {
    event.preventDefault();
    fileInputRef?.current?.click();
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const imageUrls = [];
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        imageUrls.push(imageUrl);
        setImageValue(imageUrls[0]);
        navigate(`/aftercapturedimage`, { state: { selectedRecordData: recordData } });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = async () => {
    let commonError = '';
    if (!afterImageDescription) {
      commonError = 'Please provide an improvement.';
    } else if (!imageValue) {
      commonError = 'Please select an image before updating.';
    }
    setError(commonError);
    if (!commonError) {
      await updateAfterStatus();
    }
  };

  const updateAfterStatus = async () => {
    const recordId = recordData.id;
    try {
      const formData = new FormData();
      formData.append('updated_at', new Date().toISOString());
      formData.append('description', afterImageDescription);
      formData.append('kaizen_id', recordId);
      formData.append('user_id', userData.id);
      
      const response = await axios.put(Services.UPDATE_AFTER_STATUS + recordId, formData);
      if (response.status === 200) {
        navigate(`/kaizen`);
      }
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
      // Handle the error here (e.g. display an error message)
    } 
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  return (
    <div style={{ padding: 20 }}>
      {/* Before Kaizen Information */}
      <div>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>After Kaizen</Typography>
        <Typography style={{ textAlign: 'left' }}>Prior to Kaizen, Sekurit, India</Typography>
      </div>
      {/* Display Before Image Information */}
      <div style={{ marginTop: 30 }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 25, fontWeight: 600 }}>Before Image</Typography>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>Observations</Typography>
        <Typography style={{ textAlign: 'left' }}>{description}</Typography>
        {parsedData && parsedData.length > 0 && (
            <ImageSlider images={parsedData.map(image => image.data_url)} />
          )}
      </div>
      {/* After Image Form */}
      <div style={{ marginTop: "30px" }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 25, fontWeight: 600, marginBottom: "20px" }}>After Image</Typography>
        {/* Text input for improvement */}
        <Text
          required
          fullWidth
          label="Improvements *"
          name="improvements"
          autoComplete="off"
          value={afterImageDescription}
          onChange={(event) => setAfterImageDescription(event.target.value)}
          multiline={true}
          rows={5}
        />
        {/* Display error message if there is any */}
        {error && <Typography style={{ color: 'red', fontSize: 14 }}>{error}</Typography>}
      </div>
      {/* Upload Image Section */}
      <div style={{
        border: "2px dashed #00448B",
        padding: "20px",
        flex: 1,
        display: 'flex',
        width: "100%",
        height: 300,
        marginTop: 30,
        backgroundColor: '#00448a1a',
        marginRight: 20,
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "4px"
      }}>
        <button
          onClick={handlePopover}
          style={{
            width: 30,
            height: 30,
            marginLeft: 10,
            marginBottom: 10,
            cursor: 'pointer',
            padding: 0,
            border: 'none',
            background: 'none',
          }}
          aria-label="Upload File"
        >
          <img
            src={CloudUpload}
            alt="Upload File"
            style={{ width: '100%', height: '100%' }}
          />
        </button>
        <Typography onClick={handlePopover} style={{
          textAlign: 'center',
          marginLeft: 15,
          color: 'black',
          fontWeight: 600,
          width: 170,
          fontSize: 16
        }}>Click to Capture/Upload</Typography>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          accept="image/*" 
        />
      </div>
      {/* Buttons Section */}
      <div style={{ width: '95%', display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <Button
          variant="outlined"
          style={cancelButtonStyles}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={updateButtonStyles}
          onClick={handleClick}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

const cancelButtonStyles = {
  borderRadius: '3px',
  width: "47%",
  marginLeft: 20,
  marginRight: 20,
  flex: 1,
  borderWidth: '3px',
  border: '2px solid #00448B',
  fontSize: 20,
  color: "#00448B",
  marginTop: "10px",
  textTransform: 'none',
  height: "45px",
};

const updateButtonStyles = {
  width: "48%",
  borderRadius: '3px',
  flex: 1,
  backgroundColor: "#00448B",
  fontSize: 20,
  color: "white",
  marginTop: "10px",
  textTransform: 'none',
  height: "45px",
};

export default AfterKaizenSelection;
