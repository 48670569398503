import Home from './pages/home/Home';
import CapturePhoto from './pages/before/CapturePhoto';
import CapturedImage from './pages/before/CapturedImage';
import Reports from './pages/reports/Reports';
import AfterImage from './pages/after/AfterImage';
import ImageCropper from './pages/helper/ImageCropper';
import ImageCropper1 from './pages/helper/ImageCropper1';

import Login from './pages/login/Login';
import UserManagement from './pages/usermanagement/UserManagement';
import HelpSupport from './pages/helpsupport/HelpSupport';
import ReportsFinalPage from './pages/reports/ReportsFinalPage';
import Kaizen from './pages/kaizens/Kaizen';
import BeforeKaizen from './pages/kaizens/BeforeKaizen';
import AfterKaizen from './pages/kaizens/AfterKaizen';
import AfterKaizenSelection from './pages/kaizens/AfterKaizenSelection';
import BeforeKaizenTab from './pages/kaizens/BeforeKaizenTab';
import AfterKaizenTab from './pages/kaizens/AfterKaizenTab';
import AfterCapturedImage from './pages/after/AfterCapturedImage';
import NewUser from './pages/usermanagement/NewUser';
import HomeDashBoard from './pages/home/HomeDashBoard';
import AdminDashBoard from './pages/home/AdminDashBoard';
import ProfileView from './pages/usermanagement/ProfileView';
import MyDocument from './pages/document/MyDocument';
import DocumentViewer from './pages/document/DocumentViewer';
import EmailForm from './pages/reports/EmailForm';
import { ReactComponent as DashBoardIcon } from '../src/assets/sidebar/ic_dashboard.svg';
import { ReactComponent as ReportsIcon } from '../src/assets/sidebar/ic_reports.svg';
import { ReactComponent as UserManagementIcon } from '../src/assets/sidebar/ic_user_management.svg';
import { ReactComponent as HelpSupportIcon } from '../src/assets/sidebar/ic_help_support.svg';
import { ReactComponent as KaizenIcon } from '../src/assets/sidebar/ic_kaizen.svg';

import { ReactComponent as DashBoardIconSelected } from '../src/assets/sidebar/ic_dashboard_selected.svg';
import { ReactComponent as KaizenIconSelected } from '../src/assets/sidebar/ic_kaizen_selected.svg';
import { ReactComponent as HelpSupportIconSelected } from '../src/assets/sidebar/ic_help_support_selected.svg';
import { ReactComponent as ReportsIconSelected } from '../src/assets/sidebar/ic_reports_selected.svg';
import { ReactComponent as UserManagementIconSelected } from '../src/assets/sidebar/ic_user_management_selected.svg';



const Routes = {
  mainRoutes: [

    {
      path: '/homeDashBoard',
      name: 'DashBoard',
      component: HomeDashBoard,
      icon: DashBoardIcon,
      iconSelected: DashBoardIconSelected
    },
    {
      path: '/home',
      name: 'Dashboard',
      component: Home,
    },
    {
      path: '/capturephoto',
      name: 'Before Kaizen',
      component: CapturePhoto,
    },
    {
      path: "/capturedimage",
      name: "Captured Image",
      component: CapturedImage,
    },
    {
      path: "/afterimage",
      name: "After Images",
      component: AfterImage,
    },
    {
      path: "/aftercapturedimage",
      name: "After Captured Images",
      component: AfterCapturedImage,
    },
    {
      path: '/kaizen',
      name: 'Kaizens',
      component: Kaizen,
      icon: KaizenIcon,
      iconSelected: KaizenIconSelected
    },
    {
      path: "/reports",
      name: "Reports",
      component: Reports,
      icon: ReportsIcon,
      iconSelected: ReportsIconSelected
    },
    {
      path: "/usermanagement",
      name: "User Management",
      component:UserManagement ,
      icon: UserManagementIcon,
      iconSelected: UserManagementIconSelected
    },
    {
      path: "/helpsupport",
      name: "Help & Support",
      component: HelpSupport,
      icon: HelpSupportIcon,
      iconSelected: HelpSupportIconSelected
    },
    {
      path: '/imagecropper',  
      name: 'ImageCropper',
      component: ImageCropper
    },
    {
      path: '/imagecropper1',  
      name: 'ImageCropper',
      component: ImageCropper1
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/reportsfinalpage',
      name: 'Reports Final Page',
      component: ReportsFinalPage
    },
    {
      path: '/beforekaizen',
      name: 'Before Kaizen',
      component: BeforeKaizen
    },
    {
      path: '/afterkaizen',
      name: 'After Kaizen',
      component: AfterKaizen
    },
    {
      path: '/beforekaizentab',
      name: 'Before Kaizen',
      component: BeforeKaizenTab,
    },
    {
      path: '/afterkaizentab',
      name: 'After Kaizen',
      component: AfterKaizenTab,
    },
    {
      path: '/afterkaizenselection',
      name: 'After Kaizen Selection',
      component: AfterKaizenSelection,
    },
    {
      path: '/mydocument',
      name: 'After Kaizen Selection',
      component: MyDocument,
    },
    {
      path: '/documentviewer',
      name: 'After Kaizen Selection',
      component: DocumentViewer,
    },
    {
      path: '/newuser',
      name: 'New User',
      component: NewUser,
    },
    {
      path: '/admindashboard',
      name: 'Admin Dashboard',
      component: AdminDashBoard,
    },
    {
      path: '/emailform',
      name: 'Admin Dashboard',
      component: EmailForm,
    },
    {
      path: '/profile',
      name: 'Profile View',
      component: ProfileView,
    },
  ]
};

export default Routes;
