
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import { imageType, isUpdateBeforeStatus, newKaizenUpdatestate, beforeImageDescriptionState, } from "../../recoil/atoms";
import BlobAPI from "../../services/BlobAPI";
import axios from "axios";
import Services from "../../services/Services";
import CropIcon from "../../assets/icons/ic_crop.png";
import { CircularProgress } from "@material-ui/core";
import API from "../../services/API";
import AlertDialog from "../../input/AlertDialog";
const CapturedImage = () => {
  const location = useLocation();
  const [imageValue] = useRecoilState(imageType);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const kaizenData = JSON.parse(localStorage.getItem("kaizenData"));
  const [isUpdate] = useRecoilState(isUpdateBeforeStatus);
  let recordData = location.state ? location.state.selectedRecordData : null;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [newKaizenUpdate] = useRecoilState(newKaizenUpdatestate);
  const [beforeImageDescription] = useRecoilState(beforeImageDescriptionState);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(true);
  const onSaveClick = async () => {
    if (isSaveButtonEnabled) {
      setSaveButtonEnabled(false);
      setIsLoading(true);
      if (isUpdate) {
        if (recordData.before_status && recordData.before_status.length > 0) {
          const beforeData = recordData.before_status[0];
          const parsedData = beforeData.before_image;
          uploadImageToBlobStorage(parsedData);
        } else {
          uploadImageToBlobStorage(recordData);
        }
      } else {
        submitRecord();
      }
    }
  };
  const updateBeforeStatus = async (beforeImage) => {
    const recordId = recordData.id;
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    try {
      // Transform the beforeImage array into the desired format
      const image_name = beforeImage.map(image => ({ image_name: image.image_name }));
      // Make the POST request to update the before_status in the database
      const response = await axios.put(
        Services.UPDATE_BEFORE_STATUS + recordId,
        {
          before_image: JSON.stringify(image_name), // Assign directly to the property
          updated_at: new Date().toISOString(),
          kaizen_id: recordId,
          user_id: userData.id
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`, // Assuming access_token is available
            secret_key: secret_key // Assuming secret_key is available
          }
        }
      );
      if (response.status === 200) {
        navigate(`/kaizen`);
      }
      console.log("updatedREsponse", response.data);
      // Handle the response here (e.g., update state, display a success message)
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
      // Handle the error here (e.g., display an error message)
    } finally {
      setIsLoading(false);
    }
  };
  const getLatestRecord = async () => {
    try {
      setIsLoading(true);
      const latestRecord = await API.getLatestKaizen(userData);
      if (!latestRecord) {
        setIsLoading(false);
        return "001"; // No latest record, default to "001"
      }
      let serialNumber = latestRecord.serial_number;
      const getMonthfromSerial = serialNumber.split("/")[2].substring(0, 2);
      let todayDate = new Date();
      const dateOnly = todayDate.toISOString().split("T")[0];
      const monthOnly = dateOnly.split("-")[1]; // Extract only the month
      if (monthOnly.includes(getMonthfromSerial)) {
        const n = serialNumber.slice(-3);
        let incrementedNumber = parseInt(n) + 1;
        let number = String(incrementedNumber).padStart(3, "0");
        console.log("numbernumber", number);
        setIsLoading(false);
        return number;
      } else {
        setIsLoading(false);
        return "001";
      }
    } catch (error) {
      console.log("ERROR", error);
      AlertDialog({
        type: "error",
        title: "getLatestRecord",
        text: "There is a problem with getLatestRecord",
      });
      setIsLoading(false);
    }
  };
  async function generateSerialNumber() {
    const lastThreeDigits = await getLatestRecord();
    const date = new Date();
    const month = (date.getMonth() + 1).toString().padStart(2, 0);
    const year = date.getFullYear().toString().slice(-2);
    const dateWithSerialNumber = month + year + "_" + lastThreeDigits;
    try {
      setIsLoading(true);
      const plantName = kaizenData.plant_name;
      const workCenterName = kaizenData.work_center_name;
      const getFinalPlantName = plantName.slice(0, 3).toUpperCase();
      const number =
        getFinalPlantName + "/" + workCenterName + "/" + dateWithSerialNumber;
      setIsLoading(false);
      return number;
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred:", error);
      AlertDialog({
        type: "error",
        title: "generateSerialNumber",
        text: "There is a problem with generateSerialNumber",
      });
    }
  }
  const submitRecord = async () => {
    try {
      setIsLoading(true);
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const serialNumber = await generateSerialNumber();
      const formData = new FormData();
      formData.append("plant_id", kaizenData.plant_id);
      formData.append("work_center_id", kaizenData.work_center_id);
      formData.append("serial_number", serialNumber);
      formData.append("opening_date", kaizenData.opening_date);
      formData.append("kaizen_type", kaizenData.kaizen_type);
      formData.append("user_id", userData.id);
      formData.append("updated_at", new Date().toISOString());
      formData.append("kaizen_name", kaizenData.kaizen_name);
      formData.append("observation", kaizenData.observation);
      const response = await axios.post(Services.INSERT_NEW_KAIZEN, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      if (response.status === 200) {
        await uploadImageToBlobStorage(response.data);
        // setShowDialog(true);
      } else if (response.status === 401) {
        console.error("Unauthorized");
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      
      if (error.isAxiosError && error.response) {
  const { status } = error.response;
  // Handle specific error codes or messages as needed
  if (status === 429) {
    AlertDialog({
      type: "warning",
      title: "Warning",
      text: "Too Many Requests Submitted, Please try again..",
    });
  }
} else {
  // Handle other types of errors
  console.log("Other error:", error);
}
      // Consider logging the error or sending it to a centralized logging system
    } finally {
      setIsLoading(false);
    }
  };
  const uploadImageToBlobStorage = async (data) => {
    try {
      setIsLoading(true);
      const imageDataUrl = imageValue;
      // Extract image type
      const imageType1 = imageDataUrl.substring(
        imageDataUrl.indexOf("/") + 1,
        imageDataUrl.indexOf(";")
      );
      // Extract image base64 data
      const imageBase64 = imageDataUrl.substring(imageDataUrl.indexOf(",") + 1);
      // Generate a unique image name
      const isDevUser = [
        "R6554386",
        "A2507882",
        "N5530025",
        "R0710128",
      ].includes(userData.sgid);
      const imageNamePrefix = isDevUser ? "dev_image_" : "image_";
      const randomBytes = new Uint8Array(16);
      window.crypto.getRandomValues(randomBytes);
      const randomHexString = Array.from(randomBytes)
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
      let imageName = `${imageNamePrefix}${Date.now()}_${randomHexString}.${imageType1}`;
      let serialNumber = isUpdate
        ? recordData.serial_number
        : data.serial_number;
      const getLastNumber = serialNumber.substring(
        serialNumber.lastIndexOf("/") + 1
      );
      const fileName = `before_${getLastNumber}`;
      const finalImageName = `${fileName}_${imageName}`;
      const response = await BlobAPI.uploadBlobImageAPI(
        finalImageName,
        imageBase64,
        imageType1,
        userData
      );
      if (response && response.data) {
        const beforeImage = [response.data];
        if (isUpdate) {
          const beforeStatus = recordData.before_status;
          if (beforeStatus && beforeStatus.length > 0) {
            const beforeData = beforeStatus[0];
            const parsedData = beforeData.before_image;
            parsedData.push(response.data);
            await updateBeforeStatus(parsedData);
          } else {
            insertBeforeStatus(data, beforeImage);
          }
        } else {
          insertBeforeStatus(data, beforeImage);
        }
        if (Object.keys(newKaizenUpdate).length > 0) {
          await updateNewKaizenStatus();
        }
      } else {
        setIsLoading(false);
        // Handle the case where the response status is not 200
        handleErrorResponse(response);
      }
    } catch (error) {
      handleCatchError(error, "uploadImageToBlobStorage");
    } finally {
      setIsLoading(false);
    }
  };
  const handleErrorResponse = (response) => {
    AlertDialog({
      type: "error",
      title: "Image Upload Error",
      text: `Error uploading image. Status: ${response.status}`,
    });
  };
  const handleCatchError = (error, title) => {
    if (axios.isAxiosError(error)) {
      if (!error.response) {
        AlertDialog({
          type: "error",
          title: `${title} Network Error`,
          text: "Network error. Please check your internet connection.",
        });
      } else {
        console.error("Axios Error:", error);
        AlertDialog({
          type: "error",
          title: `${title} Axios Error`,
          text: `Axios error: ${error.message}`,
        });
      }
    } else {
      console.error("Error:", error.message);
      AlertDialog({
        type: "error",
        title: `${title} Error`,
        text: `Error: ${error.message}`,
      });
    }
  };
  const updateNewKaizenStatus = async () => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    const formData = new FormData();
    formData.append("kaizen_name", newKaizenUpdate.kaizen_name);
    formData.append("kaizen_type_id", newKaizenUpdate.kaizen_type_id);
    formData.append("opening_date", newKaizenUpdate.opening_date);
    formData.append("updated_at", new Date().toISOString());
    formData.append("id", newKaizenUpdate.record_id);
    formData.append("observation", beforeImageDescription);
    try {
      setIsLoading(true);
      const response = await axios.put(
        `${Services.UPDATE_NEW_KAIZEN}${recordData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            secret_key: secret_key
          }
        }
      );
      console.log("response", response);
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    } finally {
      setIsLoading(false);
      console.log("finally call");
    }
  };
  const insertBeforeStatus = async (data, beforeImage) => {
    try {
      // Define the data to be sent in the request body
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const formData = new FormData();
      formData.append("before_image", JSON.stringify(beforeImage));
      formData.append("created_at", new Date().toISOString());
      formData.append("updated_at", new Date().toISOString());
      formData.append("kaizen_id", data.id);
      formData.append("user_id", userData.id);
      // Define the record_id to be used in the URL
      const record_id = data.id;
      // Make the POST request using Axios
      const response = await axios.post(Services.INSERT_BEFORE_STATUS + record_id, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      if (response.status === 200) {
        const serialNumber = data.serial_number;
        AlertDialog({
          type: "success",
          title: "Kaizen Created",
          text: `New Kaizen is created with the Serial Number ${serialNumber}`,
          onConfirm: () => {
            navigate(`/kaizen`);
          },
        });
      } else if (response.status === 401) {
        AlertDialog({
          type: "error",
          title: "Insert Before Status",
          text: "Unauthorized",
        });
      } else {
        AlertDialog({
          type: "error",
          title: "Insert Before Status",
          text: "Server error",
        });
      }
      console.log(response.data); // Log the response data
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const onCancelClick = () => {
    navigate(-1);
  };
  const onCropClick = () => {
    localStorage.setItem("kaizen_status", "before");
    navigate(`/imagecropper`, { state: { selectedRecordData: recordData } });
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#000000",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {/* Content */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
          minHeight: "50vh",
        }}
      >
        {imageValue && (
          <div style={{ margin: "10px" }}>
            <img
              src={imageValue}
              style={{ width: "100%", borderRadius: "10px" }}
              alt="Deleted image"
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <button
            onClick={onCropClick}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                onCropClick();
              }
            }}
            style={{
              textAlign: "center",
              marginLeft: "25px",
              cursor: "pointer",
              border: "none",
              background: "none",
              padding: 0,
            }}
            aria-label="Crop Image"
          >
            <img src={CropIcon} alt="Crop" />
          </button>
        </div>
      </div>
      {/* Bottom Section */}
      <div style={{ backgroundColor: "#FFFFFF", minHeight: "50vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            marginTop: "30px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              flex: 1,
              borderWidth: "3px",
              border: "2px solid #00448B",
              fontSize: 20,
              color: "#00448B",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={onCancelClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              flex: 1,
              backgroundColor: isSaveButtonEnabled ? "#00448B" : "#7d6fde",
              fontSize: 20,
              color: "white",
              textTransform: "none",
            }}
            onClick={onSaveClick}
            disabled={!isSaveButtonEnabled}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CapturedImage;
