import React, { useState, useEffect } from 'react';
import { Button, Typography } from "@mui/material";
import axios from 'axios';
import Services from '../../services/Services';
import { CircularProgress } from '@material-ui/core';
import NewIcon from '../../assets/icons/ic_plus_white.svg';
import editIcon from '../../assets/icons/ic_edit.svg';
import deleteIcon from '../../assets/icons/ic_delete.svg';
import { useNavigate } from 'react-router-dom';
import Card from "@material-ui/core/Card";
import DeleteDialog from '../../input/DeleteDialog';
import SearchIcon from '../../assets/icons/ic_search.svg';
import Avatar from "@mui/material/Avatar";
import AlertDialog from '../../input/AlertDialog';


const UserManagement = () => {
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteRow, setDeleteRow] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [deleting, setDeleting] = useState(false);
  const [searchText, setSearchText] = useState('');
  const userData = JSON.parse(localStorage.getItem("userData"));


  const handleNewClick = () => {
    navigate(`/newuser`, { state: {isEdit: false} });
  }
  const handleEdit = (user) => {
    navigate(`/newuser`, { state: { userInfo: user , isEdit: true} });
  }
  const handleDelete = (user) => {
    if (deleting) {
      return;
    }
    setDeleteRow(true);
    setRowIndex(user);
  }
  const rowDelete = async (user) => {
    let id = user.id;
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;   
    try {
      const response = await axios.delete(`${Services.DELETE_USER}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      if (response.data) {
        console.log("data", response.data);
        handleClose();
      }
    }
    catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  }
  useEffect(() => {
    if(userData.role_id === 5 || userData.role_id === 3){
      getUserInfoForPlant();
    }else{
      getAllUserInfo();
    }
  }, []);

  useEffect(() => {
    getRoleDetails();
  }, []);


  const getUserInfoForPlant = async () => {
   try {
    setLoading(true);
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    const response = await axios.get(`${Services.GET_USERS_FOR_PLANT}?plant_id=${userData.plant_id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`, // Assuming access_token is available
        secret_key: secret_key // Assuming secret_key is available
      }
    });
    if(response.status === 200){
      const data = response.data.users;
      if(data){
        setUserInfo(data);
        setLoading(false);
      }
    }else{
     setLoading(false);
     setError("Problem with updating users");
    }
   } catch (error) {
    setLoading(false);
    if (error.isAxiosError && error.response) {
      const { status } = error.response;
      // Handle specific error codes or messages as needed
      if (status === 429) {
        AlertDialog({
          type: "warning",
          title: "Warning",
          text: "Too Many Requests Submitted, Please try again..",
        });
      }
    } else {
      // Handle other types of errors
      setError(error);

      console.log("Other error:", error);
    }
   }
  }
  const getAllUserInfo = async () => {
    try {
      setLoading(true);
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;   
      const response = await axios.get(Services.GET_ALL_USERS, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      if (response.status === 200) {
        const data = response.data.users;
        if (data) {
          setUserInfo(data);
          setLoading(false);
        } else {
          setLoading(false);
          setError("Problem with updating users");
        }
      }
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        setError(error)
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  };
  const getRoleDetails = async () => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;   
      const response = await axios.get(Services.GET_ROLES, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      if (response.status === 200) {
          if (response?.data?.roles) {
          const roles = response.data.roles;
          setRoleList(roles);
        }
      } else {
        console.log("servererror");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const roleMap = roleList.reduce((acc, role) => {
    acc[role.id] = role.role;
    return acc;
  }, {});
  const onHandleClosed = () => {
    setDeleteRow(false);
  }

  const filteredUserInfo = userInfo
  .filter(user => {
    const selectedRoleObj = roleList.find(d => d.id === user.role_id);
    const selectedRoleName = selectedRoleObj ? selectedRoleObj.role : '';
    const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
    const username = user.username ? user.username.toLowerCase() : '';

    return (
      fullName.includes(searchText.toLowerCase()) ||
      selectedRoleName.toLowerCase().includes(searchText.toLowerCase()) ||
      username.includes(searchText.toLowerCase())
    );
  })
  .sort((a, b) => {
    const fullNameA = `${a.first_name} ${a.last_name}`.toLowerCase();
    const fullNameB = `${b.first_name} ${b.last_name}`.toLowerCase();
    return fullNameA.localeCompare(fullNameB);
  });


  const handleClose = async () => {
    setDeleteRow(false);
    // If delete operation is in progress, do not execute again
    if (deleting) {
      return;
    }
    if (deleteRow && rowIndex) {
      try {
        setDeleting(true); // Set the 'deleting' flag to true before initiating the delete
        if(userData.role_id === 5 || userData.role_id === 3){
          getUserInfoForPlant();
        }else{
          getAllUserInfo();
        }
        getAllUserInfo();
        setRowIndex(null);
      } catch (error) {
        console.error('Error deleting user:', error);
      } finally {
        setDeleting(false); // Reset the 'deleting' flag after the delete is completed or failed
      }
    }
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Generate a valid hexadecimal color value
    const color = "#" + ((hash >> 16) & 0xFF).toString(16).padStart(2, "0") +
      ((hash >> 8) & 0xFF).toString(16).padStart(2, "0") +
      (hash & 0xFF).toString(16).padStart(2, "0");

    return color;
  }

  function stringAvatar(name, size = 50) {
    if (!(name ?? '').includes(' ')) {
      return {
        sx: {
          bgcolor: '#3f51b5', // Default background color if name is not provided or doesn't contain a space
          width: size,
          height: size,
        },
        children: '',
      };
    }
    const [firstName, lastName] = name.split(' ');

    return {
      sx: {
        bgcolor: stringToColor(name),
        width: size,
        height: size,
      },

      children: `${firstName[0]}${lastName[0]}`,
    };
  }


  return (
    <div style={{ padding: 15 }}>
      <DeleteDialog open={deleteRow} onClose={onHandleClosed} handleDelete={() => rowDelete(rowIndex)} />
      {(userData.role_id === 1 || userData.role_id === 5) && (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "20px",}}>
          <Button
            variant="contained"
            style={{
              width: "107px",
              height: "40px",
              borderRadius: "3px",
              marginRight: "15px",
              backgroundColor: "#00448B",
              fontSize: 20,
              color: "white",
              marginTop: "10px",
              textTransform: "none",
            }}
            onClick={handleNewClick}
          >
          <img src={NewIcon} style={{ marginRight: 10 }} alt="button icon" /> New
          </Button>
        </div>
      )}

      {loading || error ? (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(0, 0, 0, 0.5)' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <div style={{ color: 'red', textAlign: 'center', fontSize: '18px' }}>
              {error}
            </div>
          )}
        </div>
      ) : null}
      <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: "20px" }}>
        <div style={{ position: 'relative' }}>
          <img
            src={SearchIcon}
            alt="Search Icon"
            style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
          />
          <input
            type="text"
            placeholder="Search User/Role"
            style={{ width: '100%', padding: '10px', paddingLeft: '40px', fontSize: "14px", borderRadius: "5px", border: '1px solid #000' }}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
        {filteredUserInfo
          .map((user) => (
            <Card
              key={user.id}
              style={{
                width: "100%",
                padding: 10,
                backgroundColor: "#F1F1F1",
                display: "flex",
                alignItems: "center",
              }}
              variant="outlined"
            >
            <Avatar {...stringAvatar((user.first_name[0] + " " + user.last_name[0]).toUpperCase(), 50)} />        
              <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: "10px" }}>
                <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>
                  {user.first_name.toUpperCase()} {user.last_name.toUpperCase()}
                </Typography>
                <Typography style={{ fontWeight: 400, fontSize: "14px", color: "#1D1B20" }}>{roleMap[user.role_id]}</Typography>
              </div>
              {(userData.role_id === 1 || (userData.role_id === 5 && roleMap[user.role_id] !== 'Admin')) && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div onClick={() => handleEdit(user)}>
                        <img style={{ marginRight: "10px" }} src={editIcon} alt="Edit" />
                      </div>
                      <div onClick={() => handleDelete(user)}>
                        <img src={deleteIcon} alt="Delete" />
                      </div>
                    </div>
                  )}

            </Card>
          ))}
      </div>
    </div>
  );
}
export default UserManagement;