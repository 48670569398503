import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { menuList } from "../recoil/atoms";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import routes from "../routes";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import SidebarMenuIcon from "../assets/icons/ic_side_menu.svg";
import BackIcon from "../assets/icons/ic_back.svg";
import ProfileIcon from "../assets/icons/ic_profile_outline.svg";
import logout from "../assets/icons/logout.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import Logo from "../assets/icons/ic_empower_logo.svg";
import SGLogo from "../assets/icons/ic_sg_logo.svg"
const drawerWidth = 200;

const useStyles = makeStyles(() => ({
  logoTextStyle: {
    height: "44px",
    background: "linear-gradient(90deg, #00448A 0%, #0D6BA9 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: 600,
    letterSpacing: "0em",
    textAlign: "left",
    color: "white",
    marginLeft: "10px",
    backgroundColor: "red",
    marginTop: "10px",
  },
  logoDivStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export function NavDrawer({ onClickFunction, showDeleteIcon }) {
  const classes = useStyles();
  const [menu, setMenu] = useRecoilState(menuList);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let name = "";
  let sgid = "";
  let fullNameInitials = "";

  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData) {
    name = userData.first_name;
    sgid = userData.sgid;
    const firstName = userData.first_name.charAt(0);
    const lastName = userData.last_name.charAt(0);
    fullNameInitials = firstName + lastName;
  }

  const isPostGlassRoute = location.pathname === "/capturephoto";
  const isCapturedImage = location.pathname === "/capturedimage";
  const isAfterImage = location.pathname === "/afterimage";
  const isAfterCapturedImage = location.pathname === "/aftercapturedimage";
  const isImageCropper = location.pathname === "/imagecropper";

  const isHome = location.pathname === "/home";
  const isReportsFinalPage = location.pathname === "/reportsfinalpage";
  const isBeforeKaizen = location.pathname === "/beforekaizen";
  const isAfterLKaizenSelection = location.pathname === "/afterkaizenselection";
  const isAfterKaizen = location.pathname === "/afterkaizen";
  const isNewUser = location.pathname === "/newuser";
  const isProfileView = location.pathname === "/profile";

  const changeMenu = (name) => {
    setMenu(name);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    onClickFunction();
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("kaizenData");
    window.location.href = "/login";
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  const handleKeyPress = (event) => {
    // Check if the Enter key was pressed
    if (event.key === "Enter") {
      handleLogout();
    }
  };

  const drawer = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: 280,
      }}
    >
      <div style={{ flex: 1 }}>
        <Toolbar style={{ marginTop: "20px" }}>
          <div className={classes.logoDivStyle}>
            <img src={Logo} style={{ width: 32, height: 32 }} />
            <Typography className={classes.logoTextStyle}>
              Empower Ops
            </Typography>
          </div>
        </Toolbar>
        <List>
          {routes.mainRoutes.map((text, index) => getListItemSet(text, index))}
        </List>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
  {/* Logo and copyright section */}
  <div>
  <div
    style={{
      textAlign: "center",
      backgroundColor: "#FFFFFF",
      padding: "10px",
      width: '100%', // Make it take full width
    }}
  >
    <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
      <div style={{ marginRight: "1rem" }}>
        <Avatar
          sx={{ bgcolor: "#00448B", width: 50, height: 50 }}
          onClick={() => {
            handleProfile();
            handleDrawerClose();
          }}
        >
          {fullNameInitials}
        </Avatar>
      </div>
      <div style={{ margin: "-8px" }}>
        <Typography
          style={{
            color: "#000000",
            fontSize: 20,
            fontWeight: 600,
            lineHeight: "24px",
            marginLeft: "5px",
          }}
          onClick={() => {
            handleProfile();
            handleDrawerClose();
          }}
        >
          {name}
        </Typography>
        <Typography
          style={{
            color: "#8F908F",
            fontSize: 16,
            display: "block",
            marginRight: "15px",
          }}
        >
          {sgid}
        </Typography>
      </div>
      <div>
        <img
          src={logout}
          style={{ marginLeft: 60, cursor: "pointer" }}
          alt="button icon"
          onClick={handleLogout}
          onKeyPress={handleKeyPress}
          tabIndex={0}
        />
      </div>
    </div>
  </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
      <img
        src={SGLogo} // Ensure the path to SGLogo is correct
        alt="Saint Gobain Logo"
        style={{ width: "40%", height: '40%', objectFit: 'contain' }} // objectFit ensures the logo scales without distortion
      />
    </div>

    <Typography
      style={{
        color: "#000000",
        textAlign: "center",
        fontSize: 11,
        fontWeight: 500,
        marginBottom:'10px'
      }}
    >
     Copyright © 2024 INDEC4.0
    </Typography>
  </div>

  {/* Bottom section */}

</div>


    </div>
  );

  function getListItemSet(text, index) {
    if (userData.role_id === 4 && text.name === "User Management") {
      return null; // Hide the "User Management" tab
    }
    return text.icon ? (
      <NavLink key={index} style={{ textDecoration: "none" }} to={text.path}>
        <ListItem
          key={text}
          disablePadding
          sx={{ display: "block" }}
          selected={menu === text.name}
          style={{
            backgroundColor: menu === text.name ? "#00448B" : "",
            cursor: "pointer", // Change cursor to pointer
          }}
          onClick={() => {
            changeMenu(text.name);
            setOpen(!open); // Toggle the open state when clicking anywhere in the ListItem
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 58,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              display: "flex",
              alignItems: "center",
            }}
          >
            {menu === text.name ? (
              <SvgIcon
                style={{ color: "black", marginLeft: "20px", marginTop: "8px" }}
              >
                <Tooltip title={text.name} placement="right">
                  <text.iconSelected stock={"black"} />
                </Tooltip>
              </SvgIcon>
            ) : (
              <SvgIcon
                style={{ color: "black", marginLeft: "20px", marginTop: "8px" }}
              >
                <Tooltip title={text.name} placement="right">
                  <text.icon stock={"black"} />
                </Tooltip>
              </SvgIcon>
            )}
            <ListItemText
              primary={text.name}
              sx={{ opacity: open ? 1 : 1 }}
              style={
                menu === text.name
                  ? {
                      color: "white",
                      marginLeft: "20px",
                      fontWeight: 400,
                      fontSize: 16,
                    }
                  : {
                      color: "#757575",
                      marginLeft: "20px",
                      fontWeight: 400,
                      fontSize: 16,
                    }
              }
            />
          </ListItemButton>
        </ListItem>
      </NavLink>
    ) : null;
  }

  function CustomAppBar({
    isHome,
    isReportsFinalPage,
    handleGoBack,
    setOpen,
    isPostGlassRoute,
    isCapturedImage,
    isAfterImage,
    isAfterCapturedImage,
    isImageCropper,
    isProfileView,
  }) {

    let pageTitle = "";

  if (isNewUser) {
    pageTitle = "User Management";
  } else if (isProfileView) {
    pageTitle = "Profile";
  } else if (isHome) {
    pageTitle = "Home";
  } else if (isBeforeKaizen || isAfterLKaizenSelection || isAfterKaizen) {
    pageTitle = "Kaizens";
  } else if (isReportsFinalPage) {
    pageTitle = "Reports";
  }
    if (
      isPostGlassRoute ||
      isCapturedImage ||
      isAfterImage ||
      isAfterCapturedImage ||
      isImageCropper
    ) {
      return (
        <AppBar position="fixed">
          <Toolbar style={{ backgroundColor: "black" }} />
        </AppBar>
      );
    }
    return (
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar
          style={{ backgroundColor: "white", justifyContent: "space-between" }}
        >
          {isHome ||
          isReportsFinalPage ||
          isBeforeKaizen ||
          isAfterLKaizenSelection ||
          isAfterKaizen ||
          isNewUser ||
          isProfileView ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  onClick={handleGoBack}
                  style={{ width: "auto", height: "60px" }}
                >
                  <img src={BackIcon} alt="Back" />
                </IconButton>
                <Typography
                  style={{
                    color: "#1D1B20",
                    fontWeight: 600,
                    fontSize: 20,
                    textAlign: "left",
                  }}
                >
                {pageTitle}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                {showDeleteIcon && (isBeforeKaizen || isAfterKaizen) ? (
                  <DeleteIcon
                    style={{
                      fontSize: "25px",
                      cursor: "pointer",
                      color: "black",
                    }}
                    onClick={handleDelete}
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <>
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={() => setOpen(true)}
                style={{ boxShadow: "none" }}
              >
                <img src={SidebarMenuIcon} alt="Sidebar Menu" />
              </IconButton>
              <IconButton color="inherit" style={{ boxShadow: "none" }}>
                <div className={classes.logoDivStyle}>
                  <img
                    src={Logo}
                    style={{ width: 32, height: 32 }}
                    alt="Logo"
                  />
                  <Typography className={classes.logoTextStyle}>
                    Empower Ops
                  </Typography>
                </div>
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="work"
                onClick={handleProfile}
              >
                <img src={ProfileIcon} />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
    );
  }
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CustomAppBar
          {...{
            isProfileView,
            isNewUser,
            isHome,
            isReportsFinalPage,
            handleGoBack,
            setOpen,
            isPostGlassRoute,
            isCapturedImage,
            isAfterImage,
            isAfterCapturedImage,
            isImageCropper,
          }}
        />
        <Drawer open={open} onClose={handleDrawerClose}>
          {/* SIDEBAR LIST OF ICONS TOP*/}
          {drawer}
          {/* SIDEBAR LIST OF ICONS BOTTOM*/}

          {/*       
      <List style={{paddingTop:40}}>
        {routes.mainRoutes.map((text, index) =>
          text.footer && text.iconFlag ? getListItemSet(text, index) : null
        )}
      </List> */}
        </Drawer>
      </Box>
    </div>
  );
}
export default NavDrawer;
