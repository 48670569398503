import React, { useState, useEffect } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add'; // Import the Add icon
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';


const useStyles = makeStyles(() => ({
  imageSliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
  },
  sliderImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: "10px"
  },
  arrowButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    padding: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  leftArrow: {
    left: "10px",
  },
  rightArrow: {
    right: "10px",
  },
  arrowIcon: {
    fontSize: "40px",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    fontSize: "30px",
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  imageIndex: {
    background: '#00448B', // Background color
    color: 'white', // Text color
    padding: '5px 10px', // Padding around the text
    borderRadius: '5px', // Rounded corners
  },

  number: {
    width: "20px",
    height: "20px",
    backgroundColor: "#ccc",
    borderRadius: "50%",
    margin: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeNumber: {
    backgroundColor: "#00448B",
    color: "white",
    fontSize: "12px"
  },
  iconContainer: {
    position: "absolute",
    bottom: "2px",
    right: "2px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px !important"
  },
  addIcon: {
    fontSize: "30px",
    marginRight: "10px",
    cursor: "pointer",
    color: "#00448B",
  },
  deleteIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "red",
  },
}));

const ImageSlider = ({ images, onAddIconClick, onDeleteIconClick, isKaizenEdit }) => {
  const classes = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const uuid = uuidv4();
  // Preload the next and previous images
  useEffect(() => {
    const preloadImages = (index) => {
      const nextIndex = (index + 1) % images.length;
      const prevIndex = (index - 1 + images.length) % images.length;

      const image = new Image();
      image.src = images[nextIndex];
      image.onload = () => {
        // Preloaded image is ready
      };

      const prevImage = new Image();
      prevImage.src = images[prevIndex];
      prevImage.onload = () => {
        // Preloaded image is ready
      };
    };

    preloadImages(currentImageIndex);
  }, [currentImageIndex, images]);

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleImageClick = () => {
    console.log("Image clicked");
  };
  const handleAddIconClick = () => {
    const clickedImageName = images[currentImageIndex];
    if (typeof onAddIconClick === 'function') {
      onAddIconClick(clickedImageName, currentImageIndex);
    }
  };

  const handleDeleteIconClick = (index) => {
    if (typeof onDeleteIconClick === 'function') {
      onDeleteIconClick(index);
      setCurrentImageIndex(index);
      setDeleteSuccess(true); 
    }
  };

  const handleCloseDeleteSuccess = () => {
    setDeleteSuccess(false); // Reset delete success state
  };

  return (
    <div className={classes.imageSliderContainer}>
      <button className={`${classes.arrowButton} ${classes.leftArrow}`} onClick={prevImage}>
        <KeyboardArrowLeftIcon className={classes.arrowIcon} />
      </button>
      <img
        className={classes.sliderImage}
        src={images[currentImageIndex]}
        alt={`Image ${currentImageIndex + 1}`}
        onClick={handleImageClick}
      />
      <button className={`${classes.arrowButton} ${classes.rightArrow}`} onClick={nextImage}>
        <KeyboardArrowRightIcon className={classes.arrowIcon} />
      </button>
      {/* Display numbers and highlight the current number */}
      <div className={classes.numberContainer}>
        {images.map((_, index) => (
          <span key={uuid}
            onClick={() => setCurrentImageIndex(index)}>
          </span>
        ))}
        <div className={classes.imageIndex}>{currentImageIndex + 1}/{images.length}</div>
      </div>
      {isKaizenEdit && ( // Conditionally render Edit and Delete icons
        <div className={classes.iconContainer}>
          <AddIcon className={classes.addIcon} onClick={handleAddIconClick} />
          <DeleteIcon className={classes.deleteIcon} onClick={() => handleDeleteIconClick(currentImageIndex)} />
        </div>
      )}
         <Dialog open={deleteSuccess} onClose={handleCloseDeleteSuccess}>
        <DialogTitle>Delete Success</DialogTitle>
        <DialogContent>
          <h4>Image deleted successfully!</h4>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteSuccess}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageSlider;
