import {
  Typography,
  Tooltip,
  Grid,
  Popover,
  Box,
  Checkbox,
  Button
} from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary, Container, CircularProgress, Avatar } from "@material-ui/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@material-ui/core/Card";
import API from "../../services/API";
import FilterIcon from '../../assets/icons/ic_filter_icon.svg';
import DownloadIcon from '../../assets/icons/ic_file_download.svg';
import CloseIcon from '../../assets/icons/ic_close_crop.svg';
import axios from "axios";
import Services from "../../services/Services";
import SearchIcon from '../../assets/icons/ic_search.svg';
import { makeStyles } from '@material-ui/core/styles';
import Helper from "../../services/Helper";
import TextField from '@mui/material/TextField';
import PlaceHolderIcon from "../../assets/icons/ic_placeholder.svg";
import BlobAPI from "../../services/BlobAPI";
import AlertDialog from "../../input/AlertDialog";


const useStyles = makeStyles({
  checkboxTextStyle: {
    marginLeft: "5px",
    color: "#00000",
    fontSize: "15px",
    fontWeight: 500,
  },
  avatarContainerStyle: {
    display: "flex",
    padding: "4px 3px 4px 4px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    background: "#D7EDFF",
    color: "#0452C8",
  },
});

const Reports = () => {
  const classes = useStyles()
  const [recordData, setRecordData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [plantList, setPlantList] = useState([]);
  const [plantCheckboxes, setPlantCheckboxes] = useState([]);
  const [kaizenTypeList, setKaizenTypeList] = useState([]);
  const [kaizenTypeCheckboxes, setKaizenTypeCheckboxes] = useState([]);
  const [workCenterList, setWorkCenterList] = useState([]);
  const [workCenterCheckboxes, setWorkCenterCheckboxes] = useState([]);
  const [plantAccordionExpanded, setPlantAccordionExpanded] = useState(false);
  const [workCenterAccordionExpanded, setWorkCenterAccordionExpanded] = useState(false);
  const [kaizenTypeAccordionExpanded, setKaizenTypeAccordionExpanded] = useState(false);
  const [dateRangeAccordionExpanded, setDateRangeAccordionExpanded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [fromDate, setFromDate] = useState([]);
  const [toDate, setToDate] = useState([]);
  const [visibleRecords, setVisibleRecords] = useState(10);
  const [filteredSearchRecords, setFilteredSearchRecords] = useState([]);
  const openFilterPopover = Boolean(anchorEl);
  const filterId = openFilterPopover ? 'simple-popover' : undefined;


  useEffect(() => {
    const fetchData = async () => {
      await getNewRecordData();
    };
    fetchData();
  }, []);

  const openFilter = async (event) => {
    setCoordinates([event.pageX, event.pageY]);
    setAnchorEl(event.currentTarget);
  }
  const handleDownload = async () => {
    let excelData = [];
    let filteredRecords = [];
    if (searchText && filteredSearchRecords.length > 0) {
        filteredRecords = filteredSearchRecords.filter(item => item.after_status !== null && (item.before_status && item.before_status.length > 0));
    } else if (!searchText && filteredData.length > 0) {
        filteredRecords = filteredData.filter(item => item.after_status !== null && (item.before_status && item.before_status.length > 0));
    } else {
        alert("No filtered data available for download.");
        return;
    }

    // Check if there's data available for download
    if (filteredRecords.length > 0) {
        // Your download logic here, for example:
        // Generate and download Excel file using filteredRecords
         excelData = filteredRecords.filter(item => {
            // Check if 'after_status' exists and it's not empty
            if (item.after_status && item.after_status.length > 0) {
                // Check if 'before_status' exists and it's not empty
                if (item.before_status && item.before_status.length > 0) {
                    // Check if 'before_image' exists and it's not empty
                    if (item.before_status[0].before_image && item.before_status[0].before_image.length > 0) {
                        // Check if 'after_image' exists and it's not empty
                        if (item.after_status[0].after_image && item.after_status[0].after_image.length > 0) {
                            return true; // Include this item in the filtered data
                        }
                    }
                }
            }
            return false; // Exclude this item from the filtered data
        });
        try {
            setLoading(true);
            // Simulate a delay to show the loader (remove this in production)
            await new Promise(resolve => setTimeout(resolve, 1000));

            Helper.generateExcel(excelData);

        } finally {
            setLoading(false);
        }
    } else {
        alert("No data available for download.");
    }
    // Update filteredSearchRecords with the filtered data
    setFilteredSearchRecords(filteredRecords);
}


  const handleFilter = async () => {
    try {
      setError('');
      setLoading(true);
      handleCloseFilter();

      const filteredNewRecord = await getFilteredNewRecordData();
      if (filteredNewRecord.length === 0) {
        setError('No completed kaizens');
      } else {
        setError('');
        setFilteredData(filteredNewRecord)
      }
    } catch (error) {
      console.log("ERROR", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const displayedRecords = Array.isArray(filteredData)
  ? filteredData
      .filter(
        (record) =>
          record &&
          Array.isArray(record.after_status) &&
          record.after_status !== undefined &&
          record.after_status.length > 0
      )
      .slice(0, visibleRecords)
  : [];


  const totalRecords = Array.isArray(filteredData)
  ? filteredData.reduce((count, record) => {
      return (
        count +
        (record && Array.isArray(record.after_status) && record.after_status.length > 0 ? 1 : 0)
      );
    }, 0)
  : 0;



  const getFilteredNewRecordData = async () => {
    try {
      const plantName = [];
      plantCheckboxes.find(plant => { if (plant.checked) { plantName.push(plant) } });

      const matchedKaizentype = [];
      kaizenTypeCheckboxes.find(type => { if (type.checked) { matchedKaizentype.push(type) } });

      const workCenterName = [];
      workCenterCheckboxes.find(workCenter => { if (workCenter.checked) { workCenterName.push(workCenter) } });

      const filteredData = await Promise.all(recordData.map(async (record) => {
        const recordDate = record.opening_date;
        console.log(plantName, matchedKaizentype, workCenterName);
        var filterStatus = false;
        // var kaizenStatus = false;
        if (fromDate.length > 0 && toDate.length > 0) {
          if ((!fromDate || recordDate >= fromDate) && (!toDate || recordDate <= toDate)) {
            if (plantName.length > 0 && matchedKaizentype.length > 0 && workCenterName.length > 0) {
              plantName.map(plant => {
                matchedKaizentype.map(type => {
                  workCenterName.map(workCenter => {
                    if (record.plant_id === plant.id && record.kaizen_type_id === type.id && record.work_center_id === workCenter.id) {
                      filterStatus = true;
                    }
                  })
                })
              })
            }
            else if (plantName.length > 0 && matchedKaizentype.length > 0) {
              plantName.map(plant => {
                matchedKaizentype.map(type => {
                  if (record.plant_id === plant.id && record.kaizen_type_id === type.id) {
                    filterStatus = true;
                  }
                })
              })
            }

            else if (plantName.length > 0 && workCenterName.length > 0) {
              plantName.map(plant => {
                workCenterName.map(workCenter => {
                  if (record.plant_id === plant.id && record.work_center_id === workCenter.id) {
                    filterStatus = true;
                  }
                })
              })
            }
            else if (matchedKaizentype.length > 0 && workCenterName.length > 0) {
              matchedKaizentype.map(type => {
                workCenterName.map(workCenter => {
                  if (record.kaizen_type_id === type.id && record.work_center_id === workCenter.id) {
                    filterStatus = true;
                  }
                })
              })
            }
            else if (plantName.length > 0) {
              plantName.map(plant => {
                if (record.plant_id === plant.id) {
                  filterStatus = true;
                }
              })
            }
            else if (matchedKaizentype.length > 0) {
              matchedKaizentype.map(type => {
                if (record.kaizen_type_id === type.id) {
                  filterStatus = true;
                }
              })
            }

            else if (workCenterName.length > 0) {
              workCenterName.map(workCenter => {
                if (record.work_center_id === workCenter.id) {
                  filterStatus = true;
                }
              })
            }
            else {
              filterStatus = true;
            }
          }
        }
        else {
          if (plantName.length > 0 && matchedKaizentype.length > 0 && workCenterName.length > 0) {
            plantName.map(plant => {
              matchedKaizentype.map(type => {
                workCenterName.map(workCenter => {
                  if (record.plant_id === plant.id && record.kaizen_type_id === type.id && record.work_center_id === workCenter.id) {
                    filterStatus = true;
                  }
                })
              })

            })
          }
          else if (plantName.length > 0 && matchedKaizentype.length > 0) {
            plantName.map(plant => {
              matchedKaizentype.map(type => {
                if (record.plant_id === plant.id && record.kaizen_type_id === type.id) {
                  filterStatus = true;
                }
              })
            })
          }
          else if (plantName.length > 0 && workCenterName.length > 0) {
            plantName.map(plant => {
              workCenterName.map(workCenter => {
                if (record.plant_id === plant.id && record.work_center_id === workCenter.id) {
                  filterStatus = true;
                }
              })
            })
          }
          else if (matchedKaizentype.length > 0 && workCenterName.length > 0) {
            matchedKaizentype.map(type => {
              workCenterName.map(workCenter => {
                if (record.kaizen_type_id === type.id && record.work_center_id === workCenter.id) {
                  filterStatus = true;
                }
              })
            })
          }
          else if (plantName.length > 0) {
            plantName.map(plant => {
              if (record.plant_id === plant.id) {
                filterStatus = true;
              }
            })
          }
          else if (matchedKaizentype.length > 0) {
            matchedKaizentype.map(type => {
              console.log(type)
              if (record.kaizen_type_id === type.id) {
                filterStatus = true;
              }
            })
          }
          else if (workCenterName.length > 0) {
            workCenterName.map(workCenter => {
              if (record.work_center_id === workCenter.id) {
                filterStatus = true;
              }
            })
          }
        }
        if (filterStatus) {
          console.log(filterStatus, record)
          return record;
        }
        return null;
      }));
      // Remove null values from the filteredData array
      const finalFilteredData = filteredData.filter(record => record !== null);
      console.log("finalFilteredData", finalFilteredData);
      return finalFilteredData;
    } catch (error) {
      console.error("Error filtering data:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getPlantList();
      await getKaizenTypeDetails();
    };
    fetchData();
  }, []);

  const getWorkCenterDetails = async (ids) => {
    const idArray = Array.isArray(ids) ? ids : [ids];
    const allWorkCenters = [];
    const allCheckboxes = [];

    // Fetch work center details for each ID and collect results
    for (const id of idArray) {
      const workCenters = await getWorkCenterDetailsFromID(id);
      console.log("workCenters", workCenters);
      allWorkCenters.push(...workCenters);

      // Populate checkboxes for each work center
      const checkboxes = workCenters.map((workCenter) => ({
        id: workCenter.id,
        label: workCenter.wc_name,
        checked: false,
      }));
      allCheckboxes.push(...checkboxes);
      console.log("allCheckboxes", allCheckboxes);
    }

    // Update state once all calls are completed
    setWorkCenterList(allWorkCenters);
    setWorkCenterCheckboxes(allCheckboxes);
  };


  const getWorkCenterDetailsFromID = async (id) => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(`${Services.GET_WORK_CENTER_NAME}?plant_id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      if (response.status === 200 && Array.isArray(response.data.data.work_center_name)) {
        return response.data.data.work_center_name;
      } else {
        console.log("Invalid response or work_center_name is not an array.");
      }
    } catch (error) {
         if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
    return [];
  };

  const getKaizenTypeDetails = async () => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_KAIZEN_TYPE, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }  
      });
      if (response.status === 200) {
        if (response.data.data.kaizen_type) {
          setKaizenTypeList(response.data.data.kaizen_type);

          // Populate checkboxes with data from kaizenTypeList
          const initialKaizenTypeCheckboxes = response.data.data.kaizen_type.map((kaizen) => ({
            id: kaizen.id,
            label: kaizen.name,
            checked: false,
          }));
          setKaizenTypeCheckboxes(initialKaizenTypeCheckboxes);
        }
      } else {
        console.log("servererror");
      }
    } catch (error) {
      console.log("ERROR", error); 
        if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  };


  const getPlantList = async () => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_ALL_PLANTS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }
      });      if (response.status === 200) {
        if (response.data.plant) {
          const filteredPlantList = response.data.plant.filter((plant) => plant.plant !== "All");
          if (userData.role_id === 3 || userData.role_id === 4 || userData.role_id === 5) {
            const filteredData = filteredPlantList.filter((item) => item.id === userData.plant_id);
            setPlantList(filteredData);
          } else {
            setPlantList(filteredPlantList);
          }
          // Populate checkboxes with data from plantList
          const initialCheckboxes = response.data.plant.map((plant) => ({
            id: plant.id,
            label: plant.plant,
            checked: false,
          }));
          setPlantCheckboxes(initialCheckboxes);
        }
      } else {
        console.log("servererror");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleAccordionChange = (accordionType) => {
    if (accordionType === 'plant') {
      setPlantAccordionExpanded(!plantAccordionExpanded);
    } else if (accordionType === 'workCenter') {
      setWorkCenterAccordionExpanded(!workCenterAccordionExpanded);
    } else if (accordionType === 'kaizenType') {
      setKaizenTypeAccordionExpanded(!kaizenTypeAccordionExpanded);
    } else if (accordionType === "dateRange") {
      setDateRangeAccordionExpanded(!dateRangeAccordionExpanded);
    }
  };

  const handleCheckboxChange = (id) => {
    console.log("selectedOptions", selectedOptions);
    const updatedCheckboxes = plantCheckboxes.map((checkbox) =>
      checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    );
    setPlantCheckboxes(updatedCheckboxes);
    const changedCheckbox = updatedCheckboxes.find(checkbox => checkbox.id === id);
    console.log(`Checkbox with ID ${id} (${changedCheckbox.label}) is now ${changedCheckbox.checked ? 'checked' : 'unchecked'}`);

    let chennai = false;
    let chakan = false;
    let bhiwadi = false;

    updatedCheckboxes.map(plant => {
      console.log(plant.label, plant.checked)
      if (plant.label === "Chennai" && plant.checked === true) {
        chennai = true;
      }
      else if (plant.label === "Chakan" && plant.checked === true) {
        chakan = true;
      }
      else if (plant.label === "Bhiwadi" && plant.checked === true) {
        bhiwadi = true;
      }
    });

    if (chennai && chakan && bhiwadi) {
      getWorkCenterDetails([1, 2, 3]);
    }
    else if (chennai && chakan) {
      getWorkCenterDetails([1, 2]);

    }
    else if (chakan && bhiwadi) {
      getWorkCenterDetails([2, 3]);
    }
    else if (chennai && bhiwadi) {
      getWorkCenterDetails([1, 3]);
    }
    else if (chennai) {
      getWorkCenterDetails([1]);
    }
    else if (chakan) {
      getWorkCenterDetails([2]);
    }
    else {
      getWorkCenterDetails([3]);
    }

  }


  const handleCheckboxWorkCenterChange = (id) => {
    const updatedCheckboxes = workCenterCheckboxes.map((checkbox) =>
      checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    );
    setWorkCenterCheckboxes(updatedCheckboxes); // Use setWorkCenterCheckboxes to update the state
    const changedCheckbox = updatedCheckboxes.find(checkbox => checkbox.id === id);

    console.log(`Checkbox with ID ${id} (${changedCheckbox.label}) is now ${changedCheckbox.checked ? 'checked' : 'unchecked'}`);


    const workCenterFilterData = filteredData.filter(record => record.plant_id === 1 && record.serial_number.includes(changedCheckbox.label));
    setFilteredData(workCenterFilterData)
  }

  const handleCheckboxKaizenTypeChange = (id) => {
    const updatedCheckboxes = kaizenTypeCheckboxes.map((checkbox) =>
      checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    );
    setKaizenTypeCheckboxes(updatedCheckboxes);
  };

  const allChecked = plantCheckboxes.every((checkbox) => checkbox.checked);
  const allUnchecked = plantCheckboxes.every((checkbox) => !checkbox.checked);
  const handleSelectAll = () => {
    const newAllChecked = !allChecked;
    const updatedCheckboxes = plantCheckboxes.map((checkbox) => ({
      ...checkbox,
      checked: newAllChecked,
    }));
    console.log("selectall", newAllChecked);

    if (newAllChecked) {
      getWorkCenterDetails([1, 2, 3]);
    }
    setPlantCheckboxes(updatedCheckboxes);
  };

  const allKaizenChecked = kaizenTypeCheckboxes.every((checkbox) => checkbox.checked);
  const allKaizenUnchecked = kaizenTypeCheckboxes.every((checkbox) => !checkbox.checked);
  const handleSelectAllKaizen = () => {
    const newAllChecked = !allKaizenChecked;
    const updatedCheckboxes = kaizenTypeCheckboxes.map((checkbox) => ({
      ...checkbox,
      checked: newAllChecked,
    }));
    setKaizenTypeCheckboxes(updatedCheckboxes);
  };


  // work center

  const allWorkCenterChecked = workCenterCheckboxes.every((checkbox) => checkbox.checked);
  const allWorkCenterUnchecked = workCenterCheckboxes.every((checkbox) => !checkbox.checked);
  const handleSelectAllWorkCenter = () => {
    const newAllChecked = !allWorkCenterChecked;
    const updatedCheckboxes = workCenterCheckboxes.map((checkbox) => ({
      ...checkbox,
      checked: newAllChecked,
    }));
    setWorkCenterCheckboxes(updatedCheckboxes);
    console.log(`Select All is now ${newAllChecked ? 'checked' : 'unchecked'}`);
  };


  // Function to update selected filter options
  const handleFilterOptionSelect = (filterOption) => {
    if (selectedOptions.includes(filterOption)) {
      setSelectedOptions(selectedOptions.filter(option => option !== filterOption));
    } else {
      setSelectedOptions([...selectedOptions, filterOption]);
    }
  };



  const handleFromDateChange = () => {
    setFromDate(event.target.value);
  }

  const handleToDateChange = () => {
    setToDate(event.target.value);
  }

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  const closeFilter = () => {
    setAnchorEl(null);
  }

  // let processedImageIndex = 0; // Define a variable to keep track of processed image index
  // let images = [];
 
  // const getNewRecordData = async (loadedImages) => {
  //   try {
  //     setLoading(true);

  //     let newRecord;

  //     if (userData.role_id === 4) {
  //       newRecord = await API.getNewKaizenData(userData);
  //     } else if (userData.role_id === 5 || userData.role_id === 3) {
  //       newRecord = await API.getNewKaizenDataWithPlantId(userData);
  //     } else {
  //       newRecord = await API.getOverallKaizenData();
  //     }

  //     if (newRecord) {
  //       newRecord.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //       images = [];

  //       newRecord.forEach(item => {
  //         if (item.after_status && item.after_status.length > 0) {
  //           const afterImages = JSON.parse(item.after_status[0].after_image);
  //           afterImages.forEach(image => {
  //             images.push({ image_name: image.image_name });
  //           });
  //           const beforeStatus = item.before_status && item.before_status.length > 0 ? item.before_status[0] : null;
  //           if (beforeStatus) {
  //               const beforeImages = JSON.parse(beforeStatus.before_image);
  //               beforeImages.forEach(image => {
  //                   images.push({ image_name: image.image_name });
  //               });
  //           }
  //         }
  //       });

  //       processedImageIndex = loadedImages ? loadedImages : processedImageIndex;

  //       // Calculate the range of images to pass to the API
  //       const imagesToProcess = images.slice(processedImageIndex, processedImageIndex + 20);
  //       console.log(processedImageIndex, loadedImages)
  //       // Download blob images and extract data_url
  //       const response = await BlobAPI.downloadBlobImage(imagesToProcess, "after");
  //       // Extract data_url from response and update before_status
  //       const dataURLs = response.map((res, index) => ({
  //         image_name: imagesToProcess[index].image_name,
  //         data_url: res.data_url
  //       }));

  //       const allProcessedImageData = prevImages => [...prevImages, ...dataURLs];
  //       const processedData = allProcessedImageData(allProcessedImages).length === 0 ? dataURLs : allProcessedImageData(allProcessedImages);
        
  //     setAllProcessedImages(processedData);
  //       newRecord.forEach(item => {
  //         if (item.after_status && item.after_status.length > 0) {
  //           const afterStatus = item.after_status[0];
  //           const afterImages = JSON.parse(afterStatus.after_image);
        
  //           afterImages.forEach((image) => {
  //             const imageData = processedData.find(data => data.image_name === image.image_name);
  //             if (imageData) {
  //               image.data_url = imageData.data_url;
  //             }
  //           });
  //           afterStatus.after_image = afterImages;
  //         }

  //         if (item.before_status && item.before_status.length > 0) {
  //           const beforeStatus = item.before_status[0];
  //           const beforeImages = JSON.parse(beforeStatus.before_image);
  //           beforeImages.forEach((image) => {
  //               const imageData = processedData.find(data => data.image_name === image.image_name);
  //               if (imageData) {
  //                   image.data_url = imageData.data_url;
  //               }
  //           });
  //           beforeStatus.before_image = beforeImages;
  //       }
  //       });

  //       setRecordData(newRecord);
  //       setFilteredData(newRecord);
  //       return newRecord;
  //     } else{
  //       setRecordData([]);
  //       setFilteredData([]);
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  
  // const handleClickSeeMore = async () => {
  //   try {

  //     console.log(processedImageIndex, visibleRecords);
  //     // Calculate the starting index for the next set of images
  //     const startIndex = processedImageIndex + visibleRecords;
  //     console.log(startIndex, images);
  //     // If startIndex exceeds the total number of images, reset to 0
  //     if (startIndex >= images.length) {
  //       processedImageIndex = 0;
  //     } else {
  //       processedImageIndex = startIndex;
  //     }

  //     await getNewRecordData(startIndex); 
  //     setVisibleRecords(visibleRecords + 10);
        
  //   } catch (error) {
  //     console.error('Error in handleClickSeeMore:', error);
  //   }
  // };
  const getNewRecordData = async () => {
    try {
      setLoading(true);

      let newRecord;

      if (userData.role_id === 4) {
        newRecord = await API.getNewKaizenData(userData);
      } else if (userData.role_id === 5 || userData.role_id === 3) {
        newRecord = await API.getNewKaizenDataWithPlantId(userData);
      } else {
        newRecord = await API.getOverallKaizenData(userData);
      }
      let firstTenRecords = [];
      if (newRecord.some(record => record.after_status && record.after_status.length > 0)) {
        firstTenRecords = newRecord.filter(record => record.after_status && record.after_status.length > 0).slice(0, 10);
      }
      const imageNamesArray = [];

      firstTenRecords.forEach(record => {
        if (record.after_status && record.after_status.length > 0) {
          record.after_status.forEach(afterStatus => {
            const afterImages = JSON.parse(afterStatus.after_image);
            const afterKaizenId = afterStatus.kaizen_id;
            afterImages.forEach(image => {
              imageNamesArray.push(image.image_name);
            });


            // Check if before_status exists and has the same kaizen_id
            const matchingBeforeStatus = record.before_status.find(beforeStatus => {
              return beforeStatus.kaizen_id === afterKaizenId;
            });

            if (matchingBeforeStatus) {
              const beforeImages = JSON.parse(matchingBeforeStatus.before_image);
              beforeImages.forEach(image => {
                imageNamesArray.push(image.image_name);
              });
            }


          });
        }
      });


      const imageNamesArrayLength = imageNamesArray.length;
      const response = await BlobAPI.downloadBlobImage(imageNamesArray, imageNamesArrayLength,userData);

      newRecord.forEach(item => {
        if (item.after_status && item.after_status.length > 0) {
          const afterStatus = item.after_status[0];
          const afterImages = JSON.parse(afterStatus.after_image);

          afterImages.forEach((image) => {
            const imageData = response.find(data => data.image_name === image.image_name);
            if (imageData) {
              image.data_url = imageData.data_url;
            }
          });
          afterStatus.after_image = afterImages;
        }

        if (item.before_status && item.before_status.length > 0) {
          const beforeStatus = item.before_status[0];
          const beforeImages = JSON.parse(beforeStatus.before_image);

          beforeImages.forEach((image) => {
            const imageData = response.find(data => data.image_name === image.image_name);
            if (imageData) {
              image.data_url = imageData.data_url;
            }
          });
          beforeStatus.before_image = beforeImages;
        }
      });

      setRecordData(newRecord);
      setFilteredData(newRecord)
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleClickSeeMore = async () => {
    try {
      setLoading(true);
      const nextVisibleIndex = visibleRecords + 10;
      let nextTenRecords = [];


      // Assuming newRecord is accessible, or you need to fetch it again or keep it in state
      if (recordData.some(record => record.after_status && record.after_status.length > 0)) {
        nextTenRecords = recordData.filter(record => record.after_status && record.after_status.length > 0).slice(visibleRecords, nextVisibleIndex);
      }
      const imageNamesArray = [];

      nextTenRecords.forEach(record => {
        if (record.after_status && record.after_status.length > 0) {
          record.after_status.forEach(afterStatus => {
            const afterKaizenId = afterStatus.kaizen_id;

            if (afterStatus && Array.isArray(afterStatus.after_image)) {
              afterStatus.after_image.forEach(imageData => {
                if (imageData.image_name) {
                  imageNamesArray.push(imageData.image_name);
                }
              });
            }

            console.log(imageNamesArray);

            // Check if before_status exists and has the same kaizen_id
            const matchingBeforeStatus = record.before_status.find(beforeStatus => {
              return beforeStatus.kaizen_id === afterKaizenId;
            });

            console.log(matchingBeforeStatus)
            let beforeImages;
            if (matchingBeforeStatus) {
              beforeImages = matchingBeforeStatus.before_image;
              beforeImages.forEach(image => {
                imageNamesArray.push(image.image_name);
              });
            }
          });
        }
      });

      const imageNamesArrayLength = imageNamesArray.length;

      const response = await BlobAPI.downloadBlobImage(imageNamesArray, imageNamesArrayLength,userData);

      nextTenRecords.forEach(item => {
        if (item.after_status && item.after_status.length > 0) {
          const afterStatus = item.after_status[0];
          const afterImages = afterStatus.after_image;

          afterImages.forEach((image) => {
            const imageData = response.find(data => data.image_name === image.image_name);
            if (imageData) {
              image.data_url = imageData.data_url;
            }
          });
          afterStatus.after_image = afterImages;
        }


        if (item.before_status && item.before_status.length > 0) {
          const beforeStatus = item.before_status[0];
          const beforeImages = beforeStatus.before_image;

          beforeImages.forEach((image) => {
            const imageData = response.find(data => data.image_name === image.image_name);
            if (imageData) {
              image.data_url = imageData.data_url;
            }
          });
          beforeStatus.before_image = beforeImages;
        }
      });
      // Update your state with the new records appended
      setRecordData(prevRecords => {
        const existingIds = new Set(prevRecords.map(record => record.id));
        const newRecordsToAdd = nextTenRecords.filter(newRecord => !existingIds.has(newRecord.id));
        return [...prevRecords, ...newRecordsToAdd];
      });

      setVisibleRecords(visibleRecords + 10);

    } catch (error) {
      console.error('Error in handleClickSeeMore:', error);

    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      await getKaizenTypeDetails();
    };
    fetchData();
  }, []);


  function renderRecords(records) {
    return records.length > 0 ? (
      records.map((record, index) => {
        const userInitials =
          (record?.user_first_name?.charAt(0) ?? "") +
          (record?.user_last_name?.charAt(0) ?? "");

        return (
          <div key={index}>
            <Card
              style={{
                width: "350px",
                maxWidth: 400,
                height: "auto",
                padding: "0px 8px",
                margin: "5px 0",
                backgroundColor: "#f8f8f8",
              }}
              variant="outlined"
            >
              <div
                onClick={() => handleClick(record)}
                style={{
                  width: "350px",
                  maxWidth: 400,
                  height: "auto",
                  padding: "0px 8px",
                  margin: "5px 0",
                  backgroundColor: "#f8f8f8",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={() => handleClick(record)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginLeft: 10 }}>
                    <Typography
                      style={{
                        color: "#1D1B20",
                        textAlign: "left",
                        fontSize: 16,
                        fontWeight: 580,
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {record.serial_number}
                    </Typography>
                    <Typography
                      style={{
                        color: "#49454F",
                        textAlign: "left",
                        fontWeight: 500,
                        fontSize: 12,
                        textTransform: "capitalize",
                      }}
                    >
                      {record.opening_date}
                    </Typography>
                    <Typography
                      style={{
                        color: "#49454F",
                        textAlign: "left",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      {record.kaizen_type}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Avatar className={classes.avatarContainerStyle}>
                        {userInitials}
                      </Avatar>
                      <Typography
                        style={{ color: "#49454F", marginLeft: "10px" }}
                      >
                        {record.user_first_name}
                      </Typography>
                    </div>
                  </div>
                </div>
                {record.before_status && record.before_status.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    {record.before_status[0].before_image &&
                      record.before_status[0].before_image.length > 0 &&
                      record.before_status[0].before_image[0] ? (
                      <>
                        {record.before_status[0].before_image[0].data_url ? (
                          <img
                            src={record.before_status[0].before_image[0].data_url}
                            alt={record.before_status[0].before_image[0].image_name}
                            style={{
                              width: "65px",
                              height: "65px",
                              borderRadius: 5,
                              marginRight: 10,
                            }}
                          />
                        ) : (
                          <img
                            src={PlaceHolderIcon}
                            alt="Placeholder"
                            style={{
                              width: "65px",
                              height: "65px",
                              borderRadius: 5,
                              marginRight: 10,
                            }}
                          />
                        )}

                        {/* Show badge if there are additional images */}
                        {record.before_status[0].before_image.length > 1 && (
                          <p
                            style={{
                              position: "absolute",
                              width: "24px",
                              height: "24px",
                              background: "#00448b",
                              borderRadius: "50%",
                              top: "-6px",
                              right: "4px",
                              fontSize: "14px",
                              padding: "2px 0 0",
                              margin: "0",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            +{record.before_status[0].before_image.length - 1}
                          </p>
                        )}
                      </>
                    ) : (
                      <img
                        src={PlaceHolderIcon}
                        alt="Placeholder"
                        style={{
                          width: "65px",
                          height: "65px",
                          borderRadius: 5,
                          marginRight: 10,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <img
                    src={PlaceHolderIcon}
                    alt="Placeholder"
                    style={{
                      width: "65px",
                      height: "65px",
                      borderRadius: 5,
                      marginRight: 10,
                    }}
                  />
                )}
                {/* Check if after_status is empty and show placeholder icon */}
                {record.after_status && record.after_status.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    {record.after_status[0].after_image &&
                      record.after_status[0].after_image.length > 0 && (
                        <>
                         {record.after_status[0].after_image[0] && record.after_status[0].after_image[0].data_url ? (
                            <img
                              src={record.after_status[0].after_image[0].data_url}
                              alt={record.after_status[0].after_image[0].image_name}
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: 5,
                                marginRight: 10,
                              }}
                            />
                          ) : (
                            <img
                              src={PlaceHolderIcon}
                              alt="Placeholder"
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: 5,
                                marginRight: 10,
                              }}
                            />
                          )}

                          {/* Show badge if there are additional images */}
                          {record.after_status[0].after_image.length > 1 && (
                            <p
                              style={{
                                position: "absolute",
                                width: "24px",
                                height: "24px",
                                background: "#00448b",
                                borderRadius: "50%",
                                top: "-6px",
                                right: "4px",
                                fontSize: "14px",
                                padding: "2px 0 0",
                                margin: "0",
                                color: "#fff",
                                textAlign: "center",
                              }}
                            >
                              +{record.after_status[0].after_image.length - 1}
                            </p>
                          )}
                        </>
                      )}
                  </div>
                ) : (
                  <img
                    src={PlaceHolderIcon}
                    alt="Placeholder"
                    style={{
                      width: "65px",
                      height: "65px",
                      borderRadius: 5,
                      marginRight: 10,
                    }}
                  />
                )}

              </div>
            </Card>
          </div>
        );
      })
    ) : (
      <p>No Kaizens found.</p>
    );
  }
  // const filterRecords = (records, searchText) => {
  //   return records.filter(record => {
  //     const selectedKaizenTypeObj = kaizenTypeList.find((d) => d.id === record.kaizen_type_id);
  //     const selectedKaizenName = selectedKaizenTypeObj ? selectedKaizenTypeObj.name : '';
  //     const serialNumberMatch = (record.serial_number ? record.serial_number : "").toLowerCase().includes(searchText.toLowerCase());
  //     const kaizenTypeMatch = (selectedKaizenName || '').toLowerCase().includes(searchText.toLowerCase());
  //     const openingDateMatch = ((record.opening_date ? record.opening_date : "") || '').includes(searchText);
  //     const userFirstNameMatch = ((record.user_first_name ? record.user_first_name : "") || '').toLowerCase().includes(searchText.toLowerCase());
  //     return serialNumberMatch || kaizenTypeMatch || openingDateMatch || userFirstNameMatch;
  //   });
  // };


  const filterRecords = (records, searchText) => {
    return records.filter(record => {
        // Check if 'after_status' exists and it's not empty
        if (record.after_status && record.after_status.length > 0) {
            const serialNumberMatch = (record.serial_number ? record.serial_number : "").toLowerCase().includes(searchText.toLowerCase());
            const kaizenTypeMatch = ((record.kaizen_type ? record.kaizen_type : "") || '').toLowerCase().includes(searchText.toLowerCase());
            const openingDateMatch = ((record.opening_date ? record.opening_date : "") || '').includes(searchText);
            const userFirstNameMatch = ((record.user_first_name ? record.user_first_name : "") || '').toLowerCase().includes(searchText.toLowerCase());
            return serialNumberMatch || kaizenTypeMatch || openingDateMatch || userFirstNameMatch;
        } else {
            return false; // Exclude this record from filtering
        }
    });
};

  // Function to update filteredSearchRecords
  const updateFilteredSearchRecords = (searchText) => {
    const filteredRecords = filterRecords(filteredData, searchText);
    setFilteredSearchRecords(filteredRecords);
  };

  const handleClick = (event) => {
    navigate(`/reportsfinalpage`, { state: { seletedReportData: event } });
  }
  const handleResetFilter = () => {
    console.log(workCenterAccordionExpanded);
    setPlantAccordionExpanded(false);
    setWorkCenterAccordionExpanded(false);
    setKaizenTypeAccordionExpanded(false)
    setFilteredData(recordData);
    handleCloseFilter();
    setFromDate('');
    setToDate('');
    console.log("handleCheckboxes", plantCheckboxes)
    const plantReset = plantCheckboxes.map(checkbox => ({
      ...checkbox,
      checked: false
    }));
    setWorkCenterList([]);
    const kaizenReset = kaizenTypeCheckboxes.map(checkbox => ({
      ...checkbox,
      checked: false
    }));
    setPlantCheckboxes(plantReset);
    setKaizenTypeCheckboxes(kaizenReset);
  }


  return (
    <div style={{ padding: "20px" }}>
      <div>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600, marginTop: 10 }}>Kaizen Reports</Typography>
        <Typography style={{ textAlign: 'left' }}>Overall, Sekurit India took and finished a Kaizen.</Typography>
      </div>
      <div style={{ position: 'relative', display: 'flex', width: "100%", marginTop: "10px" }}>
        <img
          src={SearchIcon}
          alt="Search Icon"
          style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
        />
        <input
          type="text"
          placeholder="Search..."
          style={{ width: '70%', padding: '10px', paddingLeft: '40px', fontSize: "14px", borderRadius: "5px", border: '1px solid #8F908F' }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            updateFilteredSearchRecords(e.target.value);
          }}
        />

        {/* Download Tooltip */}
        <Tooltip onClick={handleDownload} title="Download">
          <div style={{ position: 'absolute', top: '50%', right: '40px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: 2, marginRight: "5px" }}>
            <div style={{ border: '1px solid #8F908F', borderRadius: 5, width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={DownloadIcon} alt="Download Icon" />
            </div>
          </div>
        </Tooltip>

        {/* Filter Tooltip */}
        <Tooltip onClick={openFilter} title="Filter">
          <div style={{ position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: 1 }}>
            <div style={{ border: '1px solid #8F908F', borderRadius: 5, width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={FilterIcon} alt="Filter Icon" />
            </div>
          </div>
        </Tooltip>


        <Popover
          id={filterId}
          open={openFilterPopover}
          anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: coordinates[1], left: coordinates[0] }}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}> {/* Adjust maxHeight as needed */}
            <Grid container style={{ borderBottom: "1px solid #282A2D", display: "flex", alignItems: "center" }}>
              <Grid item xs={11}>
                <Box p={1} style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                  <Button onClick={handleFilter} style={{ fontWeight: 'bold', backgroundColor: '#00448b', color: '#ffffff', borderRadius: '5px', height: "30px" }}>
                    Apply
                  </Button>
                  <Typography style={{ fontWeight: 800, color: "#FAAD14", lineHeight: "16px", marginLeft: "25px", cursor: "pointer", fontSize: "18px" }} onClick={handleResetFilter}>Reset</Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <img
                  onClick={closeFilter}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      closeFilter();
                    }
                  }}
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  src={CloseIcon}
                  alt="Close"
                  tabIndex="0" // Ensure the element is focusable
                />
              </Grid>
            </Grid>

            <Card style={{ width: "300px", padding: "10px" }}>
              <Container component="main" maxWidth="xs">
                <Accordion expanded={plantAccordionExpanded} onChange={() => handleAccordionChange('plant')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#000000' }}>Plant</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {userData.role_id !== 3 && userData.role_id !== 4 && userData.role_id !== 5 && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Checkbox
                            checked={allChecked}
                            indeterminate={!allChecked && !allUnchecked}
                            onChange={handleSelectAll}
                            style={{
                              color: allChecked ? '#00448b' : '#D3D3D3', // Checked color: '#00448b', Unchecked color: default
                            }}
                          />
                          <Typography className={classes.checkboxTextStyle}>Select All</Typography>
                        </div>
                      )}
                      {plantList.map((plant) => (
                        <div key={plant.id} style={{ display: "flex", flexDirection: 'row' }}>
                          <Checkbox
                            value={plant.id}
                            checked={plantCheckboxes.find(checkbox => checkbox.id === plant.id)?.checked || false}
                            onChange={() => {
                              handleCheckboxChange(plant.id);
                              handleFilterOptionSelect(plant.plant); // Add this line to update selected options
                            }}
                            style={{
                              color: plantCheckboxes.find(checkbox => checkbox.id === plant.id)?.checked ? '#00448b' : '#D3D3D3',
                            }}
                          />
                          <Typography className={classes.checkboxTextStyle}>{plant.plant}</Typography>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={workCenterAccordionExpanded} onChange={() => handleAccordionChange('workCenter')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                    <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#000000' }}>Work Center</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Checkbox
                          checked={allWorkCenterChecked}
                          indeterminate={!allWorkCenterChecked && !allWorkCenterUnchecked}
                          onChange={handleSelectAllWorkCenter}
                          style={{
                            color: allChecked ? '#00448b' : '#D3D3D3', // Checked color: '#00448b', Unchecked color: default
                          }}
                        />
                        <Typography className={classes.checkboxTextStyle}>Select All</Typography>
                      </div>
                      {workCenterList.map((workCenter) => {
                        const plantSuffix =
                          workCenter.plant_id === 1 ? " - CHE" :
                            workCenter.plant_id === 2 ? " - CHA" :
                              " - BHI";

                        return (
                          <div key={workCenter.id} style={{ display: "flex", flexDirection: 'row' }}>
                            <Checkbox
                              value={workCenter.id}
                              checked={workCenterCheckboxes.find(checkbox => checkbox.id === workCenter.id)?.checked || false}
                              onChange={() => {
                                handleCheckboxWorkCenterChange(workCenter.id);
                                handleFilterOptionSelect(workCenter.wc_name);
                              }}
                              style={{
                                color: workCenterCheckboxes.find(checkbox => checkbox.id === workCenter.id)?.checked ? '#00448b' : '#D3D3D3',
                              }}
                            />
                            <Typography className={classes.checkboxTextStyle}>
                              {workCenter.wc_name} {plantSuffix}
                            </Typography>
                          </div>
                        );
                      })}

                    </div>
                  </AccordionDetails>
                </Accordion>

                {/* Kaizen Type Options Accordion */}
                <Accordion expanded={kaizenTypeAccordionExpanded} onChange={() => handleAccordionChange('kaizenType')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#000000' }}>Kaizen Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Checkbox
                          checked={allKaizenChecked}
                          indeterminate={!allKaizenChecked && !allKaizenUnchecked}
                          onChange={handleSelectAllKaizen}
                          style={{
                            color: allChecked ? '#00448b' : '#D3D3D3',
                          }}
                        />
                        <Typography className={classes.checkboxTextStyle}>Select All</Typography>
                      </div>
                      {kaizenTypeList.map((kaizen) => (
                        <div key={kaizen.id} style={{ display: "flex", flexDirection: 'row' }}>
                          <Checkbox
                            value={kaizen.id}
                            checked={kaizenTypeCheckboxes.find(checkbox => checkbox.id === kaizen.id)?.checked || false}
                            onChange={() => {
                              handleCheckboxKaizenTypeChange(kaizen.id);
                              handleFilterOptionSelect(kaizen.name);
                            }
                            }
                            style={{
                              color: kaizenTypeCheckboxes.find(checkbox => checkbox.id === kaizen.id)?.checked ? '#00448b' : '#D3D3D3',
                            }}
                          />
                          <Typography className={classes.checkboxTextStyle}>{kaizen.name}</Typography>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>

                {/* Date Range Options Accordion */}
                <Accordion expanded={dateRangeAccordionExpanded} onChange={() => handleAccordionChange('dateRange')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#000000' }}>Kaizen Date</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {/* From Date */}
                      <TextField
                        id="fromDate"
                        label="From Date"
                        type="date"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ width: "220px" }}
                      />
                      {/* To Date */}
                      <TextField
                        id="toDate"
                        label="To Date"
                        type="date"
                        value={toDate}
                        onChange={handleToDateChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginTop: "20px", width: "220px" }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>

              </Container>

            </Card>
          </div>
        </Popover>
      </div>

      <div>
        {error && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {error}
          </div>
        )}
        {loading ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <CircularProgress />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
            {searchText ? (
              renderRecords(filteredSearchRecords)
            ) : (
              renderRecords(displayedRecords)
            )}
            {visibleRecords < totalRecords && (
              <div style={{ marginTop: '20px', display: 'flex', flex: 1 }}>
                <Button
                  variant="contained"
                  style={{
                    width: '200px',
                    backgroundColor: '#00448B',
                    textTransform: 'none',
                    fontSize: 24,
                    color: 'white',
                    borderRadius: '5px',
                    textAlign: 'center',
                  }}
                  onClick={handleClickSeeMore}
                >
                  See More
                </Button>
              </div>
            )}
            <Typography style={{ color: "#000000", fontSize: "25px", fontWeight: 600, marginTop: "10px" }}>
              {visibleRecords <= totalRecords ? `${visibleRecords} of ${totalRecords}` : `${totalRecords} of ${totalRecords}`}
            </Typography>
          </div>

        )}
      </div>
    </div>
  );
}
export default Reports;
