const _get_app_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-empowerops.saint-gobain.com/";
    case "prod":
      return "https://dc02.saint-gobain.com/chryso";
    default:
      return "http://localhost:3000";
  }
};

const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-empowerops-api.saint-gobain.com/";
    case "prod":
      return "https://dc02.saint-gobain.com/api/aim/v2";
    default:
      return "http://localhost:8000/";
  }
};

const _get_auth_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.indec4.saint-gobain.com/auth/";
    case "prod":
      return "https://dc02.saint-gobain.com/auth/";
    default:
      return "https://dev.indec4.saint-gobain.com/auth/";
  }
};
const _get_sso_url = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_SekuritSmartFactoryMobileapplication&redirect_uri=https://dev-empowerops-api.saint-gobain.com/login&service=simple&scope=sgid";
    case "prod":
      return "https://dc02.saint-gobain.com/aimapi/v1/graphql";
    default:
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_SekuritSmartFactoryMobileapplication&redirect_uri=http://localhost:8000/login&service=simple&scope=sgid";
  }
};

const SGid = function () {
  const UserId = localStorage.getItem("UserID");
  return UserId;
};
export const configParam = {
  primaryColor: "#FF6D04",
  lightBackground: "#FC8C200C",
  grey: "#D8D8D8",
  API_URL: _get_api_url(),
  SG_ID: SGid(),
  AUTH_URL: _get_auth_url(),
  APP_URL: _get_app_url(),
  SSO_URL : _get_sso_url(),
};

export default configParam;
