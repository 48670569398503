import Services from "./Services";
import axios from 'axios';
import AlertDialog from "../input/AlertDialog";

const API = {
  async getNewKaizenData(userData) {
    try{
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(`${Services.GET_NEW_KAIZEN}?user_id=${userData.id}&plant_id=${userData.plant_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key: secret_key
        }
      });
      if (response.status === 200) {
        let data = response.data;
        return data.result.new_kaizen;
      } else if (response.status === 401) {
        return "Unauthorized"
      } else {
        return "Server Error"
      }
    }catch(error){
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }

  },

  async getOverallKaizenData(userData) {
    try{
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_ALL_KAIZENS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key: secret_key
        }
      });
      if (response.status === 200) {
        let data = response.data;
        return data.result.new_kaizen;
      } else if (response.status === 401) {
        return "Unauthorized"
      } else {
        return "Server Error"
      }
    }catch(error){
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }

  },

  async getNewKaizenDataWithPlantId(userData) {
    try{
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(`${Services.GET_ALL_KAIZENS_WITH_PLANT}?plant_id=${userData.plant_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key: secret_key
        }
      });
      if (response.status === 200) {
        let data = response.data;
        return data.result.new_kaizen;
      } else if (response.status === 401) {
        return "Unauthorized"
      } else {
        return "Server Error"
      }
    }catch(error){
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  },
  // async getLatestRecordData() {
  //   try {
  //     const response = await axios.get(`${Services.GET_ALL_KAIZENS}`);
  //     let data = response.data;
  //     const newRecords = data.result.new_kaizen;
  //     console.log("newRecords", newRecords);
  //     if (newRecords.length > 0) {
  //       const sortedRecords = newRecords.sort((a, b) => a.id - b.id);
  //       const latestRecord = sortedRecords[sortedRecords.length - 1];
  //       console.log("latestRecord", latestRecord);
  //       return latestRecord;
  //     } else {
  //       console.log("No new records found");
  //       return null;
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //     return null;
  //   }
  // },

  async getLatestRecordData() {
    try {
      const response = await axios.get(`${Services.GET_ALL_KAIZENS}`);
      let data = response.data;
      const newRecords = data.result.new_kaizen;

      if (newRecords.length > 0) {
        const sortedRecords = newRecords.sort((a, b) => {
          // Parse the dates and compare them
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA; // Sort in descending order (latest first)
        });

        const latestRecord = sortedRecords[0]; // The first record will be the latest
        console.log("latestRecord", latestRecord);
        return latestRecord;
      } else {
        console.log("No new records found");
        return null;
      }
    } catch (error) {
      console.log("Error:", error);
      return null;
    }
  },

  async getLatestKaizen(userData) {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_LATEST_KAIZEN, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key: secret_key
        }
      });
      if (response.status === 200) {
        let data = response.data;
        const newKaizen = data.result;
        return newKaizen;
      } else if (response.status === 401) {
        return "Unauthorized"
      } else {
        return "Server Error"
      }
    } catch (error) {
      console.log("Error:", error);
      return null;
    }
  },


  async getBeforeStatusCount(userData) {
    try {
      const response = await axios.get(`${Services.GET_BEFORE_STATUS_COUNT}?user_id=${userData.id}`)
      if (response.status === 200) {
        if (response && response.data) {
          let count = response.data.result;
          return count;
        }
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  },
  async getAfterStatusCount(userData) {
    try {
      const response = await axios.get(`${Services.GET_AFTER_STATUS_COUNT}?user_id=${userData.id}`)

      if (response.status === 200) {
        if (response?.data) {
          let count = response.data.result;
          return count;
        }
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  },

  async fetchDbConnectionDetails() {
    try {
      const response = await axios.get(`${Services.GET_DB_DETAILS}`);
      if (response.status === 200) {
        return response;
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  },

  async sendMail(formData) {
    try {
      const response = await axios.post(Services.SEND_MAIL_API, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error sending email:', error);
      throw new Error('Failed to send email.');
    }
  },
  async getAllUserInfoCount() {
    try {
      const response = await axios.get(`${Services.GET_ALL_USERS_COUNT}`);
      if (response?.data) {
        let count = response.data.result;
        return count;
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  },

  async getLocationDetails(userData) {
    try {

      const access_token = userData.access_token;
      const secret_key = userData.secret_key;

      const response = await axios.get(Services.GET_ALL_PLANTS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key: secret_key
        }
      });
      if (response.status === 200) {
        if (response && response.data) {
          const filteredPlantList = response.data.plant.filter((plant) => plant.plant !== "All");
          let locationcount = filteredPlantList.length;
          return locationcount;
        }
      } else {
        console.log("server error");
      }
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
      console.log("ERROR", error);
    }
  },

  async getWorkCenterCount(userData) {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_WORK_CENTER_NAME_COUNT, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }
      }); // Assuming Services.GET_LOCAION is defined elsewhere
      if (response.status === 200) {
        let count = response.data.result;
        return count;
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  },

  async getWorkCenterCountWithPlantId(userData) {
    try {
      const plant_id = userData.plant_id;
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(`${Services.GET_WORK_CENTER_NAME}?plant_id=${plant_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }
      });
      if (response.status === 200) {
        let WorkCenterCount = response.data.data.work_center_name.length;
        return WorkCenterCount;
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  },


  async getUserInfoForPlantCount(userData) {
    const plant_id = userData.plant_id;
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    try {
      const response = await axios.get(`${Services.GET_USERS_FOR_PLANT}?plant_id=${plant_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }
      });
      if (response.status === 200) {
        const data = response.data.users;
        if (data) {
          return data.length;
        }
      } else {
        console.log("Problem with updating users");
      }
    } catch (error) {
      console.log("error", error);
    }
  },

  // async getPlantName(id) {
  //   try {
  //     const response = await axios.get(`${Services.GET_PLANT_NAME}?id=${id}`)
  //     if (response.status === 200) {
  //       if (response && response.data) {
  //         return response.data.plant;
  //       }
  //     } else {
  //       console.log("server error");
  //     }
  //   } catch (error) {
  //     console.log("ERROR", error);
  //   }
  // },

  // async getWorkCenterName(id) {
  //   try {
  //     const response = await axios.get(`${Services.GET_WORK_CENTER_NAME_FROM_ID}?id=${id}`)
  //     if (response.status === 200) {
  //       if (response && response.data) {
  //         return response.data.wc_name;
  //       }
  //     } else {
  //       console.log("server error");
  //     }
  //   } catch (error) {
  //     console.log("ERROR", error);
  //   }
  // },

  // async getKaizenTypeName(id) {
  //   try {
  //     const response = await axios.get(`${Services.GET_KAIZEN_TYPE_NAME_FROM_ID}?id=${id}`)
  //     if (response.status === 200) {
  //       if (response && response.data) {
  //         return response.data.kaizen_type;
  //       }
  //     } else {
  //       console.log("server error");
  //     }
  //   } catch (error) {
  //     console.log("ERROR", error);
  //   }
  // },
  async getUserDataFromId(id) {
    try {
      const response = await axios.get(`${Services.GET_USER_DATA_FROM_ID}?id=${id}`)
      if (response.status === 200) {
        if (response && response.data) {
          return response.data.users;
        }
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  },

  async getWorkCenterNamesForOperator(id) {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const response = await axios.get(`${Services.GET_WORK_CENTER_NAME}?plant_id=${id}`);
      if (response.status === 200) {
        if (response.data.data.work_center_name) {
          const filteredWorkCenterList = response.data.data.work_center_name;

          // Assuming that you have the list of work center IDs as an array
          const workCenterIdsToFilter = userData && userData.work_center_id ? userData.work_center_id : [];

          const filteredData = filteredWorkCenterList.filter((workCenter) =>
            workCenterIdsToFilter.includes(workCenter.id)
          );
          const wcNames = filteredData.map((workCenter) => workCenter.wc_name);
          return wcNames;
        }
      } else {
        console.log("servererror");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }


}

export default API;
