import React, { useState } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  imageType,
  isUpdateAfterStatus,
  afterImageDescriptionState,
} from "../../recoil/atoms";
import { Button } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import CropIcon from "../../assets/icons/ic_crop.png";
import Services from "../../services/Services";
import BlobAPI from "../../services/BlobAPI";
import { CircularProgress } from "@material-ui/core";
import AlertDialog from "../../input/AlertDialog";

const AfterCapturedImage = () => {
  const location = useLocation();
  const [imageValue] = useRecoilState(imageType);
  const [isUpdate] = useRecoilState(isUpdateAfterStatus);
  const recordData = location.state ? location.state.selectedRecordData : null;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [afterImageDescription] = useRecoilState(afterImageDescriptionState);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);



  const onCropClick = () => {
    localStorage.setItem("kaizen_status", "after");
    navigate(`/imagecropper`, { state: { selectedRecordData: recordData } });
  };

  const onSaveClick = async () => {
    if (saveButtonEnabled) {
      setSaveButtonEnabled(false);
     setIsLoading(true);

    const imageDataUrl = imageValue;
    const imageType = imageDataUrl.substring(
      imageDataUrl.indexOf("/") + 1,
      imageDataUrl.indexOf(";")
    );
    const imageBase64 = imageDataUrl.substring(imageDataUrl.indexOf(",") + 1);
    const isDevUser = ["R6554386", "A2507882", "N5530025", "R0710128"].includes(
      userData.sgid
    );
    const randomBytes = new Uint8Array(16);
    window.crypto.getRandomValues(randomBytes);
    const randomHexString = Array.from(randomBytes)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

    const imageName = isDevUser
      ? `dev_image_${Date.now()}_${randomHexString}.${imageType}`
      : `image_${Date.now()}_${randomHexString}.${imageType}`;

    const serialNumber = recordData.serial_number;
    const getLastNumber = serialNumber.substring(
      serialNumber.lastIndexOf("/") + 1
    );
    const fileName = "after" + "_" + getLastNumber;
    const finalImageName = `${fileName}_${imageName}`;

    try {
      const response = await BlobAPI.uploadBlobImageAPI(
        finalImageName,
        imageBase64,
        imageType,
        userData
      );

      if (response && response.data) {
        const dataArray = [response.data];

        if (isUpdate) {
          const afterData = recordData.after_status[0];
          const parsedAfterData = afterData.after_image;
          parsedAfterData.push(response.data);
          updateAfterStatus(parsedAfterData);
        } else {
          insertAfterStatus(dataArray);
        }
      } else {
        setIsLoading(false);
        handleErrorResponse(response);
      }
    } catch (error) {
      handleCatchError(error, "onSaveClick");
    } finally {
      setIsLoading(false);
    }
  }
  };

  const handleErrorResponse = (response) => {
    AlertDialog({
      type: 'error',
      title: 'After Image Upload Error',
      text: `Error uploading image. Status: ${response.status}`,
    });
  };

  const handleCatchError = (error, title) => {
    if (axios.isAxiosError(error)) {
      if (!error.response) {
        AlertDialog({
          type: 'error',
          title: `After ${title} Network Error`,
          text: 'Network error. Please check your internet connection.',
        });
      } else {
        console.error("Axios Error:", error);
        AlertDialog({
          type: 'error',
          title: `After ${title} Axios Error`,
          text: `Axios error: ${error.message}`,
        });
      }
    } else {
      console.error("Error:", error.message);
      AlertDialog({
        type: 'error',
        title: `After ${title} Error`,
        text: `Error: ${error.message}`,
      });
    }
  };
  const updateAfterStatus = async (afterImage) => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;   
     const recordId = recordData.id;
    const image_name = afterImage.map(image => ({ image_name: image.image_name }));

    try {
      // Define the data to be sent in the request body
      const formData = new FormData();
      formData.append("after_image", JSON.stringify(image_name));
      formData.append("updated_at", new Date().toISOString());
      formData.append("description", afterImageDescription);
      formData.append("kaizen_id", recordId);
      formData.append("user_id", userData.id);

      // Make the POST request using Axios
      const response = await axios.put(
        Services.UPDATE_AFTER_STATUS + recordId,
        formData,
        {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }
      }
      );
      
      if (response.status === 200) {
        AlertDialog({
          type: "success",
          title: "After Kaizen",
          text: "After Kaizen Updated Successfully",
          onConfirm: () => {
            navigate(`/kaizen?tab=1`);
          },
        });
      }
      // Handle the response here (e.g. update state, display a success message)
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const insertAfterStatus = async (afterImage) => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;   
    let recordId = recordData.id;
    try {
      // Define the data to be sent in the request body
      const formData = new FormData();
      formData.append("after_image", JSON.stringify(afterImage));
      formData.append("created_at", new Date().toISOString());
      formData.append("updated_at", new Date().toISOString());
      formData.append("description", afterImageDescription);
      formData.append("kaizen_id", recordId);
      formData.append("user_id", userData.id);
      // Make the POST request using Axios
      const response = await axios.post(
        Services.INSERT_AFTER_STATUS + recordId,
        formData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            secret_key:secret_key
          }
        }
      );
      if (response.status === 200) {
        AlertDialog({
          type: "success",
          title: "After Kaizen",
          text: "After Kaizen Inserted Successfully",
          onConfirm: () => {
            navigate(`/kaizen?tab=1`);
          },
        });
      }
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
      setIsLoading(false);
       } finally {
      setIsLoading(false);
    }
  };
  const onCancelClick = () => {
    navigate(-1);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onCropClick();
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#000000",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {/* Content */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
          minHeight: "50vh",
        }}
      >
        {imageValue && (
          <div style={{ margin: "10px" }}>
            <img
              src={imageValue}
              style={{ width: "100%", borderRadius: "10px" }}
              alt="Deleted image"
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            onClick={onCropClick}
            onKeyPress={handleKeyPress}
            tabIndex={0} // Make the element focusable
            style={{
              textAlign: "center",
              marginLeft: "25px",
              cursor: "pointer",
            }}
          >
            <img src={CropIcon} alt="Crop" />
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div style={{ backgroundColor: "#FFFFFF", minHeight: "50vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            marginTop: "30px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              flex: 1,
              borderWidth: "3px",
              border: "2px solid #00448B",
              fontSize: 20,
              color: "#00448B",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={onCancelClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              flex: 1,
              backgroundColor: saveButtonEnabled ? "#00448B" : "#7d6fde",         
              fontSize: 20,
              color: "white",
              textTransform: "none",
            }}
            onClick={onSaveClick}
            disabled={!saveButtonEnabled}   
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AfterCapturedImage;
