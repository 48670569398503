import React, { useEffect, useState, useRef } from "react";
import { Typography, Button } from "@mui/material";
import { useRecoilState } from 'recoil';
import { useNavigate, useLocation } from "react-router-dom";
import { isUpdateBeforeStatus, imageType, beforeImageDescriptionState, newKaizenUpdatestate } from '../../recoil/atoms';
import Text from "../../input/Text";
import CustomSelect from "../../input/CustomSelect";
import axios from "axios";
import Services from "../../services/Services";
import ImageSlider from "../../input/ImageSlider";
import BlobAPI from "../../services/BlobAPI";
import SuccessDialog from '../../input/SuccessDialog';
import CloudUpload from '../../assets/icons/ic_cloud_upload.svg';
import NavDrawer from "../../app/LeftDrawer";
import DeleteDialog from "../../input/DeleteDialog";
import AlertDialog from "../../input/AlertDialog";

const BeforeKaizen = () => {
  const [, setIsUpdate] = useRecoilState(isUpdateBeforeStatus);
  const [, setimageValue] = useRecoilState(imageType);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isKaizenEdit, setIsKaizenEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);

  const location = useLocation();
  const recordData = location.state ? location.state.selectedRecordData : null;
  const [kaizenName, setKaizenName] = useState(recordData ? recordData.kaizen_name : '');
  const plantName = recordData?.plant_name ?? '';
  const workCenterName = recordData?.work_center_name ?? '';
  const kaizenType = recordData?.kaizen_type ?? '';
  const [kaizenList, setKaizenList] = useState([]);
  const [selectedKaizenType, setSelectedKaizenType] = useState(recordData.kaizen_type_id);

  const [updatedNewKaizenResponse, setUpdatedNewKaizenResponse] = useState();

  const fileInputRef = useRef(null);
  const beforeData = recordData?.before_status?.[0];
  const parsedData = beforeData?.before_image || [];
  const [beforeImages, setBeforeImages] = useState(parsedData);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(recordData.opening_date);
  const [beforeImageDescription, setBeforeImageDescription] = useRecoilState(beforeImageDescriptionState);
  const [, setNewKaizenUpdate] = useRecoilState(newKaizenUpdatestate);
  const isAfterStatusEmpty = !recordData.after_status || recordData.after_status.length === 0;
  const [error, setError] = useState('');



  useEffect(() => {
    if (recordData?.observation) {
      setBeforeImageDescription(recordData.observation);
    }
  }, [recordData]);


  useEffect(() => {
    const fetchData = async () => {
      await getKaizenTypeDetails();
    };
    fetchData();
  }, []);

  const getKaizenTypeDetails = async () => {
    try {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;
      const response = await axios.get(Services.GET_KAIZEN_TYPE, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key:secret_key
        }  
      });
      if (response.data.data.kaizen_type) {
        setKaizenList(response.data.data.kaizen_type);
      }
     else {
      console.log("server error");
    }
    } catch (error) {
      console.log("ERROR", error);
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  };
  const handleKaizenNameChange = (event) => {
    const newKaizenName = event.target.value; // Get the new kaizen name from the input
    setKaizenName(newKaizenName);
  }

  const handleEditClick = async () => {
    setIsKaizenEdit(true);
  }

  const handleUpdateClick = async () => {
    if (isKaizenEdit) {
      await updateNewKaizenStatus();
      await updateBeforeStatus(beforeImages);
    }

  }
  const updateNewKaizenStatus = async () => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    const formData = new FormData();
    formData.append('kaizen_name', kaizenName);
    formData.append('kaizen_type_id', selectedKaizenType);
    formData.append('opening_date', selectedDate);
    formData.append('updated_at', new Date().toISOString());
    formData.append('id', recordData.id);
    formData.append('observation', beforeImageDescription);
    try {
   const response = await axios.put(
        `${Services.UPDATE_NEW_KAIZEN}${recordData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            secret_key: secret_key
          }
        }
      );
      console.log("responsefromupdated", response);
      setUpdatedNewKaizenResponse(response.data);
      setShowDialog(true);
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    } finally {
      console.log("finally call");
    }
  }


  const handleKaizenTypeChange = (selectedValue) => {
    setSelectedKaizenType(selectedValue);
  };

  const handleDateSelection = (event) => {
    const value = event.target.value;
    setSelectedDate(value);
  }
  const handleDeleteIconClick = (index) => {
    // Ensure the index is within the valid range
    if (index >= 0 && index < beforeImages.length) {
      const imageName = beforeImages[index].image_name;

      deleteExistingImageFromBlob(imageName);

      setBeforeImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages.splice(index, 1);

        // Call a function to update the before status
        updateBeforeStatus(updatedImages);
        return updatedImages;
      });
    } else {
      console.error('Invalid index:', index);
    }
  }
  const updateBeforeStatus = async (beforeImage) => {
    const recordId = recordData.id;
    try {
      // Define the data to be sent in the request body
      const formData = new FormData();
      formData.append('before_image', JSON.stringify(beforeImage));
      formData.append('updated_at', new Date().toISOString());
      formData.append('kaizen_id', recordId);
      formData.append('user_id', userData.id);
      // Make the POST request using Axios
      const response = await axios.put(Services.UPDATE_BEFORE_STATUS + recordId, formData);
      if (response.status === 200) {
        console.log("Before status updated successfully");
        setBeforeImages(beforeImage);
      }
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    } finally {
      console.log("loader", "called");
    }
  };




  const deleteExistingImageFromBlob = async (imageName) => {
    try {
      await BlobAPI.deleteBlobImageAPI(imageName,userData);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChangeBeforeDescription = (event) => {
    setBeforeImageDescription(event.target.value);
  };

  const handleAddIconClick = () => {
    if (beforeImages.length < 4) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      alert("You can only add up to 4 images.");
    }
  };
  const handleOpenPopover = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const handleAfterClick = () => {
    if (!beforeImages || beforeImages.length === 0) {
      setError("Please select an image.");
      setIsUpdate(true);
    } else {
      setimageValue("");
      const updatedRecordData = { ...recordData };
      if (isKaizenEdit) {
        // Check if updatedNewKaizenResponse is defined and has a data property
        if (updatedNewKaizenResponse?.data) {
          const updatedNewKaizen = updatedNewKaizenResponse.data;
          updatedRecordData.kaizen_name = updatedNewKaizen.kaizen_name;
          updatedRecordData.kaizen_type_id = updatedNewKaizen.kaizen_type_id;
          updatedRecordData.opening_date = updatedNewKaizen.opening_date;
          // Now you can use updatedNewKaizen
        } else {
          console.error("updatedNewKaizenResponse or its data property is undefined.");
        }
      }
      // Update the before_status property with the new array 
      if (updatedRecordData.before_status && updatedRecordData.before_status.length > 0) {
        updatedRecordData.before_status[0].before_image = JSON.stringify(beforeImages);
        updatedRecordData.before_status[0].description = beforeImageDescription;

      } else {
        // If before_status doesn't exist, create a new array with the new image array
        updatedRecordData.before_status = [
          {
            before_image: JSON.stringify(beforeImages),
            description: beforeImageDescription,
          },
        ];
      }
      // Check if the 'after_status' field contains 'after_image'
      if (updatedRecordData.after_status && updatedRecordData.after_status.length > 0) {
        // Redirect to the 'afterkaizen' page
        navigate(`/afterkaizen`, { state: { selectedRecordData: updatedRecordData } });
      } else {
        // Redirect to the 'afterkaizenselection' page
        navigate(`/afterkaizenselection`, { state: { selectedRecordData: updatedRecordData } });
      }
    }
  }


  const onClickFunction = () => {
    // Implement the function you want to call in ComponentB
    console.log('Function in ComponentB was called.');
    setDeleteRow(true);
  };

  const handleFileSelect = (event) => {

    const selectedFiles = event.target.files; // Get the selected files from the input
    const maxAllowedFiles = 2;
    // Check if the number of selected files exceeds the maximum allowed
    if (selectedFiles.length > maxAllowedFiles) {
      alert(`You can only select up to ${maxAllowedFiles} images.`);
      // Clear the file input to prevent uploading more files
      fileInputRef.current.value = null;
    } else {
      const imageUrls = []; // Array to store the URLs of selected images

      // Example: Display the selected images
      for (const selectedFile of selectedFiles) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const imageUrl = e.target.result;
          imageUrls.push(imageUrl); // Store the URL in the array

          // Display the image
          const image = new Image();
          image.src = imageUrl;
          document.body.appendChild(image);

          // Log the first image URL
          console.log("Selected image URLs:", imageUrls[0]);
          setimageValue(imageUrls[0]);
          setIsUpdate(true);

          if (isKaizenEdit) {
            const newKaizenData = {
              kaizen_name: kaizenName,
              kaizen_type_id: selectedKaizenType,
              opening_date: selectedDate,
              record_id: recordData.id,
            }
            setNewKaizenUpdate(newKaizenData);
          }
          navigate(`/capturedimage`, { state: { selectedRecordData: recordData } });
        };
        reader.readAsDataURL(selectedFile); // Read the selected file as a data URL
      }
    }
  };

  const rowDeleteKaizen = async () => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    let id = recordData.id;
    for (const image of beforeImages) {
      const imageName = image.image_name;
      deleteExistingImageFromBlob(imageName);
    }
    try {
      const response = await axios.delete(`${Services.DELETE_NEW_KAIZEN}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });
      if (response.data) {
        setShowDialog(true);
        onHandleDeleteClosed();
        navigate(`/kaizen`);
      }
    }
    catch (error) {
      console.log("ERROR", error)
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  }

  const onHandleClosed = () => {
    setShowDialog(false);
  }
  const onHandleDeleteClosed = () => {
    setDeleteRow(false);
  }
  return (
    <div style={{ padding: "20px" }}>
      {isAfterStatusEmpty && userData.role_id === 5 && (
        <NavDrawer onClickFunction={onClickFunction}
          showDeleteIcon={true} />
      )}
      <SuccessDialog open={showDialog} onClose={onHandleClosed} msg={isKaizenEdit ? "New Kaizen Updated Successfully" : "New Kaizen Deleted Successfully"} />
      <DeleteDialog open={deleteRow} onClose={onHandleDeleteClosed} handleDelete={() => rowDeleteKaizen()} />
      <div>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>Before Kaizen</Typography>
        <Typography style={{ textAlign: 'left' }}>Prior to Kaizen, Sekurit, India </Typography>
      </div>
      <div>
        <div style={{ marginTop: "30px" }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Kaizen Name"
            value={kaizenName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
            onChange={isKaizenEdit ? handleKaizenNameChange : null}
            isKaizenEdit={isKaizenEdit}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30, }}>
          <Text
            style={{ width: "100%", textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Location"
            value={plantName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Work Center"
            value={workCenterName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Serial Number"
            value={recordData.serial_number}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>


        <div style={{ marginTop: "30px" }}>
          {isKaizenEdit ? (
            <CustomSelect
              label="Kaizen Type"
              value={selectedKaizenType}
              onChange={handleKaizenTypeChange}
              list={kaizenList}
              name="name"
              id="id"
              isKaizenEdit={isKaizenEdit}
            />
          ) : (
            <Text
              style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
              id="outlined-read-only-input"
              label="Kaizen Type"
              value={kaizenType}
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
        </div>

        <div style={{ marginTop: "30px" }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: "left" }}
            id="date"
            label="Date"
            type={isKaizenEdit ? 'date' : null}
            variant="outlined"
            value={selectedDate}
            onChange={isKaizenEdit ? handleDateSelection : null}
            InputLabelProps={{
              shrink: true,
            }}
            isKaizenEdit={isKaizenEdit}
          />
        </div>

        <div style={{ marginTop: "30px" }}>
          <Typography style={{ textAlign: 'left', color: 'black', fontSize: 25, fontWeight: 600, marginBottom: "20px" }}>Before Kaizen</Typography>
          <Text
            required
            fullWidth
            label="Observations"
            name="description"
            autoComplete="off"
            value={beforeImageDescription}
            onChange={isKaizenEdit ? handleChangeBeforeDescription : null}
            multiline={true}
            rows={5}
            isKaizenEdit={isKaizenEdit}
          />
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <div>
          {Array.isArray(beforeImages) && beforeImages.length > 0 ? (
            <div>
              {isKaizenEdit ? (
                <div>
                  <ImageSlider
                    images={beforeImages.map((image) => image.data_url)}
                    onAddIconClick={handleAddIconClick}
                    onDeleteIconClick={handleDeleteIconClick}
                    isKaizenEdit={true}
                  />
                </div>
              ) : (
                <div>
                  <ImageSlider
                    images={beforeImages.map((image) => image.data_url)}
                  />
                </div>
              )}

            </div>
          ) : (
            <div>
              <div
                style={{
                  border: "2px dashed #00448B",
                  padding: "20px",
                  flex: 1,
                  display: "flex",
                  width: "100%",
                  height: 300,
                  marginTop: 30,
                  backgroundColor: "#00448a1a",
                  marginRight: 20,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                }}
              >
                <img
                  onClick={handleOpenPopover}
                  src={CloudUpload}
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                  alt="My Image"
                />
                <Typography
                  onClick={handleOpenPopover}
                  style={{
                    textAlign: "center",
                    marginLeft: 15,
                    color: "black",
                    fontWeight: 600,
                    width: 170,
                    fontSize: 16,
                  }}
                >
                  Click to Capture/Upload
                </Typography>
              </div>
              {error && <Typography style={{ color: 'red', fontSize: 14 }}>{error}</Typography>}

            </div>

          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
            accept="image/*"
          />
        </div>
      </div>
      <div style={{ width: '95%', display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <Button
          variant="outlined"
          style={{
            borderRadius: '3px',
            width: "47%",
            marginLeft: 20,
            marginRight: 20,
            flex: 1,
            display: 'flex',
            borderWidth: '3px',
            border: '2px solid #00448B',
            fontSize: 20,
            color: "#00448B",
            marginTop: "10px",
            textTransform: 'none',
            height: "45px",
          }}
          onClick={isKaizenEdit ? handleUpdateClick : handleEditClick}
        >
          {isKaizenEdit ? "Update" : "Edit"}
        </Button>
        <Button
          variant="contained"
          style={{
            width: "48%",
            borderRadius: '3px',
            flex: 1,
            display: 'flex',
            backgroundColor: "#00448B",
            fontSize: 20,
            color: "white",
            marginTop: "10px",
            textTransform: 'none',
            height: "45px",
          }}
          onClick={handleAfterClick}>
          After
        </Button>
      </div>
    </div>
  )
}
export default BeforeKaizen;
