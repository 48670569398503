import React, { useEffect, useState } from "react";
import { Card, Typography } from '@mui/material';
import BeforeImageIcon from '../../assets/icons/ic_before_image.svg';
import API from "../../services/API";
import { CircularProgress } from '@material-ui/core'; 
const AdminDashboard = () => {
  const [totalKaizenCount, setTotalKaizenCount] = useState(0);
  const [beforeStatusCount, setBeforeStatusCount] = useState(0);
  const [afterStatusCount, setAfterStatusCount] = useState(0);
  const [operatorCount, setOperatorCount] = useState(0);
  const [locationCount, setLocationCount] = useState(0);
  const [workCenterCount, setWorkCenterCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    // Use Promise.all to wait for all API calls to complete
    Promise.all([getAllRecords(), getAllUserInfoCount(), getLocationList(), getWorkCenterListCount()])
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const getAllRecords = async () => {
    try {
      let response = '';
      if (userData.role_id === 1 || userData.role_id === 2) {
        response = await API.getOverallKaizenData(userData);
      } else if (userData.role_id === 5 || userData.role_id === 3) {
        response = await API.getNewKaizenDataWithPlantId(userData);
      }
  
      if (response?.length) {
        let TotalKaizenCount = response.length;
        setTotalKaizenCount(TotalKaizenCount);
  
        let before_status_count = 0;
        let after_status_count = 0;
  
        response.forEach((record) => {
          before_status_count += record.before_status ? record.before_status.length : 0;
          after_status_count += record.after_status ? record.after_status.length : 0;
        });
  
        setBeforeStatusCount(before_status_count);
        setAfterStatusCount(after_status_count);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  

  const getAllUserInfoCount = async () => {
    try {
      let response;
      if(userData.role_id === 1 || userData.role_id === 2){
         response = await API.getAllUserInfoCount();
      }else{
         response = await API.getUserInfoForPlantCount(userData);
         console.log("response", response);
      }
      setOperatorCount(response)
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  
  const getWorkCenterListCount = async () => {
    try {
      let response;
      if(userData.role_id === 3 || userData.role_id === 4 || userData.role_id === 5){
         response = await API.getWorkCenterCountWithPlantId(userData);      
      }else{
        response = await API.getWorkCenterCount(userData);
      }
      setWorkCenterCount(response)
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const getLocationList = async () => {
    try {
      if(userData.role_id === 3 || userData.role_id === 4 || userData.role_id === 5){
        setLocationCount(1);
      }else{
        const response = await API.getLocationDetails(userData); // Call getLocationDetails without "API."
        setLocationCount(response);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const cardData = [
    { title: locationCount, content: 'Total Plant' },
    { title: workCenterCount, content: 'Work Center' },
    { title: totalKaizenCount, content: 'Total Kaizen' },
    { title: operatorCount, content: 'No.of Users' },
    { title: beforeStatusCount, content: 'Before Kaizen' },
    { title: afterStatusCount, content: 'After Kaizen' },
  ];

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
        {cardData.map((card) => (
          <div key={card.id} style={{ flexBasis: '50%', maxWidth: '50%',padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card style={{ alignItems: 'center', width: 156, height: 144, backgroundColor: '#EAEAEA', borderRadius: 4 }}>
              <img src={BeforeImageIcon} alt="Add Icon" style={{ margin: 10 }} />
              <Typography style={{ color: 'black', textAlign: 'center', fontWeight: 500, fontSize: 32, textTransform: 'capitalize', fontFamily: 'Roboto', fontStyle: 'normal', lineHeight: "24px" }}>{card.title}</Typography>
              <Typography style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', fontWeight: 500, fontSize: 18, textTransform: 'capitalize', marginTop: 5, fontFamily: 'Roboto', fontStyle: 'normal'}}>{card.content}</Typography>
            </Card>
          </div>
        ))}
      </div>
      )
       }
    </div>
  );
  
};

export default AdminDashboard;
